import React, { useEffect, useState } from "react";
import "./navbar.css";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const [icon, setIcon] = useState(false);

  useEffect(() => {
    const nav = document.querySelector(".navbarMain");
    let lastScrollY = window.scrollY;
    window.addEventListener("scroll", () => {
      if (lastScrollY < window.scrollY) {
        nav.classList.add("navbarMain--hidden");
      } else {
        nav.classList.remove("navbarMain--hidden");
      }
      lastScrollY = window.scrollY;
    });
  }, []);

  const navigate = useNavigate();

  const mobilNvigate = (e) => {
    navigate(e);
    setIcon(false);
  };
  return (
    <div class="navbarMain" style={{ width: "10" }}>
      <div class="col-11 m-auto d-flex justify-content-between  align-items-center">
        <div clas="d-flex gap-5 align-items-center">
          <img
            src={require("./images/logo.png")}
            style={{ height: "33px", cursor: "pointer" }}
            alt=""
            onClick={() => navigate("/")}
          />
        </div>
        <div class="">
          <div class="navPc">
            <div class="d-flex gap-4 align-items-center" style={{}}>
              <p
                class=""
                style={{
                  cursor: "pointer",
                  fontSize: "18px",
                  fontWeight: "500",
                }}
                onClick={() => navigate("/")}
              >
                Home
              </p>
              <p
                class=""
                style={{
                  cursor: "pointer",
                  fontSize: "18px",
                  fontWeight: "500",
                }}
                onClick={() => navigate("/about")}
              >
                About
              </p>
              <p
                class=""
                style={{
                  cursor: "pointer",
                  fontSize: "18px",
                  fontWeight: "500",
                }}
                onClick={() => navigate("/animal-nutrition")}
              >
                Animal Nutrition
              </p>
              <p
                class=""
                style={{
                  cursor: "pointer",
                  fontSize: "18px",
                  fontWeight: "500",
                }}
                onClick={() => navigate("/all-products")}
              >
                All Products
              </p>

              {/* <p class='' style={{cursor:'pointer',fontSize:'18px',fontWeight:'500'}}onClick={()=>navigate('/')}>Blog</p>  */}

              <div
                type="button"
                class="blackBtn"
                onClick={() => navigate("/contact")}
              >
                <div class="blackText">Connect with us</div>
                <div class="blackBg">Connect with us</div>
              </div>
            </div>
          </div>
          <div class="navMb">
            <div
              id="menu"
              class={icon ? "icon" : ""}
              style={{ zIndex: "99999" }}
              onClick={() => setIcon(!icon)}
            >
              <div id="bar1" class="bar"></div>
              <div id="bar2" class="bar"></div>
              <div id="bar3" class="bar"></div>
            </div>
            <div class="col-12 ">
              <div
                style={icon ? { display: "block" } : { display: "none" }}
                class="nav col-md-6 col-sm-8 col-12"
                id="nav"
              >
                <div style={{ height: "100px" }}></div>
                <div class="d-grid gap-5 align-items-center text-white m-auto">
                  <p
                    class="fw-semibold"
                    onClick={() => mobilNvigate("/")}
                    style={{ cursor: "pointer" }}
                  >
                    Home
                  </p>
                  <p
                    class="fw-semibold"
                    onClick={() => mobilNvigate("/about")}
                    style={{ cursor: "pointer" }}
                  >
                    About
                  </p>
                  <p
                    class="fw-semibold"
                    onClick={() => mobilNvigate("/animal-nutrition")}
                    style={{ cursor: "pointer" }}
                  >
                    Animal Nutrition
                  </p>
                  <p
                    class="fw-semibold"
                    onClick={() => mobilNvigate("/all-products")}
                    style={{ cursor: "pointer" }}
                  >
                    All Products
                  </p>

                  {/* <p class='fw-semibold' onClick={()=>mobilNvigate('#')} style={{cursor:'pointer'}}>Blog</p>  */}
                  <div
                    type="button"
                    class="whiteBtn m-auto"
                    onClick={() => mobilNvigate("/contact")}
                  >
                    <div class="whiteText">Connect with us</div>
                    <div class="whiteBg">Connect with us</div>
                  </div>
                </div>
              </div>
            </div>
            {/* <p data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample"><i class="bi bi-list"></i></p> */}
          </div>
          {/* <div class="offcanvas offcanvas-end bg-black" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                <div class="offcanvas-header">
                  <div></div>
                 
                  <button type="button" class='btn'  data-bs-dismiss="offcanvas" aria-label="Close"><i class="bi bi-x-lg text-white"></i></button>
                </div>
                <div class="offcanvas-body">
                  <div class='d-grid gap-4 align-items-center text-white'> 
                    <p class='fw-semibold' data-bs-dismiss="offcanvas" aria-label="Close" style={{cursor:'pointer'}}>Home</p> 
                    <p class='fw-semibold' data-bs-dismiss="offcanvas" aria-label="Close" style={{cursor:'pointer'}}>Animal Nutrition</p>
                    <p class='fw-semibold' data-bs-dismiss="offcanvas" aria-label="Close" style={{cursor:'pointer'}}>Crop Science</p>
                    <p class='fw-semibold' data-bs-dismiss="offcanvas" aria-label="Close" style={{cursor:'pointer'}}>About</p>
                    <p class='fw-semibold' data-bs-dismiss="offcanvas" aria-label="Close" style={{cursor:'pointer'}}>Blog</p> 
                  
                    <button type="button" class="btn btn-light rounded-5 px-3 fw-semibold m-auto" style={{width:'fit-content'}} data-bs-dismiss="offcanvas" aria-label="Close">Connect with us</button>
                  </div>
                </div>
              </div> */}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
