import React, { useEffect } from "react";
import AOS from "aos";
import "./home.css";
import "aos/dist/aos.css";
import { DrowLine3, DrowLine4 } from "./Svgs";
import { useNavigate } from "react-router-dom";

const UpFooter = () => {
  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);

  const navigate = useNavigate();
  return (
    <div style={{ background: "white", height: "100%", position: "relative" }}>
      <div
        style={{
          position: "absolute",
          clipPath: " inset(50% 0 0 0 round 100px 100px 0 0)",
          background: "black",
          top: "0",
          height: "100%",
          width: "100%",
        }}
      >
        <br />
      </div>
      <div>
        <div
          class="col-11 m-auto row p-5 gap-md-0 gap-5"
          data-aos="zoom-in"
          style={{ borderRadius: "150px", background: "#0081F3" }}
        >
          <div
            class="text-start col-md-10 col-12"
            style={{ position: "relative" }}
          >
            <p class="display-4 fw-bold text-white mx-4">
              {/* Get to know us better, You People!{" "} */}
              Transforming Animal Health, Unleash Prosperity with Our Solutions!
              <span class="animated">
                <DrowLine4 />
              </span>{" "}
            </p>
            <div
              class="animated footerSvg"
              style={{ position: "absolute", left: "25%" }}
            >
              <DrowLine3 />
            </div>
          </div>
          <div class="col-md-2 col-12 d-flex align-items-center text-start">
            <div
              type="button"
              class="whiteBtn m-auto"
              onClick={() => navigate("/about")}
            >
              <div class="whiteText">Learn More</div>
              <div class="whiteBg">Learn More</div>
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>

    // <div
    //   class="col-11 m-auto row p-5 gap-md-0 gap-5"
    //   data-aos="zoom-in"
    //   style={{ borderRadius: "150px", background: "#0081F3" }}
    // >
    //   <div class="text-start col-md-10 col-12" style={{ position: "relative" }}>
    //     <p class="display-4 fw-bold text-white mx-4">
    //       {/* Get to know us better, You People!{" "} */}
    //       Transforming Animal Health, Unleash Prosperity with Our Solutions!
    //       <span class="animated">
    //         <DrowLine4 />
    //       </span>{" "}
    //     </p>
    //     <div
    //       class="animated footerSvg"
    //       style={{ position: "absolute", left: "25%" }}
    //     >
    //       <DrowLine3 />
    //     </div>
    //   </div>
    //   <div class="col-md-2 col-12 d-flex align-items-center text-start">
    //     <div type="button" class="whiteBtn m-auto" onClick={()=>navigate('/about')} >
    //       <div class="whiteText">Learn More</div>
    //       <div class="whiteBg">Learn More</div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default UpFooter;
