import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export const WeCare = ({ images }) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1120 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1120, min: 690 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 690, min: 0 },
      items: 1,
    },
  };
  return (
    <Carousel
      responsive={responsive}
      infinite={true}
      autoPlay={false}
      arrows={true}
      autoPlaySpeed={2500}
      showDots={false}
    >
      {images?.map((el) => {
        return (
          <div
            class="col-lg-9 col-md-10 col-11 m-auto"
            style={{ height: "550px" }}
          >
            <div class="col-12 bg-light" style={{borderRadius:'25px 25px 0 0 '}}>
              <img src={el?.img} style={{ width: "250px" }} alt="" />
            </div>
            <div
              class="bg-light  col-12 m-auto p-4"
              style={{borderRadius:'0 0 25px 25px' }}
            >
              {/* <p class="fw-bold " style={{ fontSize: "25px" }}>
                {el?.titile}
              </p> */}
              <p class=" my-2" style={{ fontSize: "18px", fontWeight: "400" }}>
                {el?.text}
              </p>
              <div type="button" class="blueBtn mt-4 text-end m-auto  ">
                <div class="blueText">Contact Us</div>
                <div class="blueBg">Contact Us</div>
              </div>
            </div>
            
          </div>
        );
      })}
    </Carousel>
  );
};

export const Testimonials = ({ data }) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1120 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1120, min: 850 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 850, min: 0 },
      items: 1,
    },
  };
  return (
    <Carousel
      responsive={responsive}
      infinite={true}
      autoPlay={false}
      arrows={true}
      autoPlaySpeed={2500}
      showDots={false}
    >
      {data?.map((el) => {
        return (
          <div>
            {el}
            <br />
          </div>
        );
      })}
    </Carousel>
  );
};

export const OurCapabilities = ({ data }) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1330 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1330, min: 990 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 990, min: 690 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 690, min: 0 },
      items: 1,
    },
  };
  return (
    <Carousel
      class="ourOfferingSlider"
      responsive={responsive}
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={2000}
      showDots={false}
      arrows={false}
    >
      {data.map((el, i) => {
        return (
          <div
            class="ourCap m-auto"
            style={{
              width: "310px",
              height: "372px",
              backgroundImage: `url(${el.bgImage})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "100%",
            }}
            data-aos="zoom-in"
          >
            <div style={{ height: ".1px" }}></div>
            <div class="ourCapDiv">
              <div
                class=" cardNo"
                style={{ height: "50px", position: "relative", top: "30px" }}
              >
                {el.no}
                <p class="fs-1" style={{ position: "relative", top: "-65px" }}>
                  {i + 1}
                </p>
              </div>
              <div
                class="cardText col-10 m-auto"
                style={{ position: "relative", top: "65px" }}
              >
                <p class="fw-bold fs-5">{el.title}</p>
                <p class="" style={{ fontSize: "15px" }}>
                  {el.text}
                </p>
              </div>
            </div>
          </div>
        );
      })}
    </Carousel>
  );
};


export const OurBrands = ({ data }) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1450 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 1450, min: 1100 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1100, min: 690 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 690, min: 0 },
      items: 1,
    },
  };
  return (
    <Carousel
      class="ourOfferingSlider"
      responsive={responsive}
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={2000}
      showDots={false}
      arrows={false}
    >
      {data.map((el) => {
        return (
           <div>
            <img src={el.img} style={{height:'150px',width:'auto',margin:'auto'}} alt="" />
           </div>
        );
      })}
    </Carousel>
  );
};