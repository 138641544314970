import logo from './logo.svg';
import './App.css';
import Navbar from './component/Navbar';
import Home from './component/Home';
import Footer from './component/Footer';
import { Route, Routes } from 'react-router-dom';
import Contact from './component/Contact';
import About from './component/About';
import CropScience from './component/CropScience';
import SoilPage from './component/SoilPage';
import AnimalNutrition from './component/AnimalNutrition';
import ScrollToTop from './component/ScrollToTop';
import EnquiryForm from './component/EnquiryForm';
import { useEffect, useState } from 'react';



function App() {
  const [show, setShow] = useState()
  const [query, setQuery] = useState()

  useEffect(() => {
    setQuery(show)
  }, [show])

  return (
    <div className="App">
      <ScrollToTop />
      <Navbar />
      {query ? <EnquiryForm data={setShow} /> : ''}
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/all-products' element={<CropScience />} />
        {/* <Route path='/products' element={<SoilPage />} /> */}
        <Route path='/animal-nutrition' element={<AnimalNutrition />} />
        <Route path='/contact' element={<Contact data={setShow} />} />

      </Routes>

        <Footer />
    </div>
  );
}

export default App;
