import React, { useEffect, useState } from "react";
import "./home.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { OurBrands, OurCapabilities, Testimonials, WeCare } from "./MultiCarousel";
import UpFooter from "./UpFooter";
import { Crown, DrowLine1, DrowLine2, Hand, Pig, ThumsUp } from "./Svgs";
import "./svg.css";
import { useNavigate } from "react-router-dom";

const weCare = [
  {
    titile: "VetChol",
    id: "poultry",
    text: "Unlocking Optimal Health and Nervous System Vitality!",
    img: `${require("./images/products/VETCHOL.png")}`,
  },
  {
    titile: "VetOTM",
    id: "poultry",
    text: "Organic trace mineral",
    img: `${require("./images/products/vetotm.png")}`,
  },
  {
    titile: "Vet SDIC",
    id: "poultry",
    text: "Purify with Power: Ultimate Defense Against Waterborne Threats! ",
    img: `${require("./images/products/vetsdic.png")}`,
  },
  {
    titile: "Vet Flora",
    id: "poultry",
    text: "Elevate Gut Health, Empower Wellness!",
    img: `${require("./images/products/vetflora.png")}`,
  },
  {
    titile: "Mycotoxcease",
    id: "poultry",
    text: "Our Shield Against Harmful Mycotoxins",
    img: `${require("./images/products/myco.png")}`,
  },
  {
    titile: "VetMOS",
    id: "poultry",
    text: "For Comprehensive toxin management",
    img: `${require("./images/products/vetmos.png")}`,
  },
  // {
  //   titile: "Vet Bind",
  //   id: "poultry",
  //   text: "Give the litter box a little love, with quick-clumping organic tofu pellets.",
  //   img: `${require("./images/products/mycoNewlogo2.png")}`,
  // },
  // {
  //   titile: "Vet Bind",
  //   id: "cattle",
  //   text: "Give the litter box a little love, with quick-clumping organic tofu pellets.",
  //   img: `${require("./images/products/mycoNewlogo2.png")}`,
  // },
  {
    titile: "Mycotoxease",
    id: "cattle",
    text: "Our Shield Against Harmful Mycotoxins",
    img: `${require("./images/products/myco.png")}`,
  },
  {
    titile: "Vet Zeo",
    id: "aqua",
    text: "For effective pond cleaning",
    img: `${require("./images/products/zeo.png")}`,
  },
  {
    titile: "Mycotoxease",
    id: "aqua",
    text: "Our Shield Against Harmful Mycotoxins",
    img: `${require("./images/products/myco.png")}`,
  },
  {
    titile: "Vet SDIC",
    id: "aqua",
    text: "Powerful water Disinfectant ",
    img: `${require("./images/products/vetsdic.png")}`,
  },
];

const ourCapabilities = [
  {
    bgImage: `${require("./images/ourCard1.png")}`,
    no: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="71"
        height="79"
        viewBox="0 0 71 79"
        fill="none"
      >
        <path
          d="M47.55 2C40.6417 2.62589 31.836 3.43683 26.5115 6.87745C19.8643 11.1727 13.7908 16.3653 8.87997 21.6776C3.22619 27.7936 1.75479 33.5007 2.0318 40.6601C2.37009 49.4033 3.65479 58.7191 10.5324 66.2338C16.6327 72.8992 26.4123 78.5816 38.385 76.5999C50.208 74.6429 59.0778 67.1551 63.9379 59.7145C69.0589 51.8745 70.5521 43.1066 67.2258 34.764C64.47 27.8524 57.5129 21.8949 50.5482 16.8121C46.4321 13.8082 41.888 10.8293 36.0682 9.5978C30.0655 8.32753 23.937 8.85534 17.8065 8.90274"
          stroke-width="3"
          stroke-linecap="round"
        />
      </svg>
    ),
    title: "Commitment to Sustainability",
    text: "Our focus is on formulating and manufacturing sustainable, environment friendly products for overall health and productivity ofanimals",
  },
  {
    bgImage: `${require("./images/ourCard2.png")}`,
    no: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="71"
        height="79"
        viewBox="0 0 71 79"
        fill="none"
      >
        <path
          d="M47.55 2C40.6417 2.62589 31.836 3.43683 26.5115 6.87745C19.8643 11.1727 13.7908 16.3653 8.87997 21.6776C3.22619 27.7936 1.75479 33.5007 2.0318 40.6601C2.37009 49.4033 3.65479 58.7191 10.5324 66.2338C16.6327 72.8992 26.4123 78.5816 38.385 76.5999C50.208 74.6429 59.0778 67.1551 63.9379 59.7145C69.0589 51.8745 70.5521 43.1066 67.2258 34.764C64.47 27.8524 57.5129 21.8949 50.5482 16.8121C46.4321 13.8082 41.888 10.8293 36.0682 9.5978C30.0655 8.32753 23.937 8.85534 17.8065 8.90274"
          stroke-width="3"
          stroke-linecap="round"
        />
      </svg>
    ),
    title: "Quality at Every Step",
    text: "All Our products undergo stringent quality check protocols right from procurement of raw material to manufacturing process to ensure compliance of highest quality standards.",
  },
  {
    bgImage: `${require("./images/ourCard3.png")}`,
    no: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="71"
        height="79"
        viewBox="0 0 71 79"
        fill="none"
      >
        <path
          d="M47.55 2C40.6417 2.62589 31.836 3.43683 26.5115 6.87745C19.8643 11.1727 13.7908 16.3653 8.87997 21.6776C3.22619 27.7936 1.75479 33.5007 2.0318 40.6601C2.37009 49.4033 3.65479 58.7191 10.5324 66.2338C16.6327 72.8992 26.4123 78.5816 38.385 76.5999C50.208 74.6429 59.0778 67.1551 63.9379 59.7145C69.0589 51.8745 70.5521 43.1066 67.2258 34.764C64.47 27.8524 57.5129 21.8949 50.5482 16.8121C46.4321 13.8082 41.888 10.8293 36.0682 9.5978C30.0655 8.32753 23.937 8.85534 17.8065 8.90274"
          stroke-width="3"
          stroke-linecap="round"
        />
      </svg>
    ),
    title: "Better Customer Service",
    text: "Our strong connect and constant dialogue with our stake holders, customers help us evolve better in terms of customer service.",
  },
  {
    bgImage: `${require("./images/ourCard3.png")}`,
    no: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="71"
        height="79"
        viewBox="0 0 71 79"
        fill="none"
      >
        <path
          d="M47.55 2C40.6417 2.62589 31.836 3.43683 26.5115 6.87745C19.8643 11.1727 13.7908 16.3653 8.87997 21.6776C3.22619 27.7936 1.75479 33.5007 2.0318 40.6601C2.37009 49.4033 3.65479 58.7191 10.5324 66.2338C16.6327 72.8992 26.4123 78.5816 38.385 76.5999C50.208 74.6429 59.0778 67.1551 63.9379 59.7145C69.0589 51.8745 70.5521 43.1066 67.2258 34.764C64.47 27.8524 57.5129 21.8949 50.5482 16.8121C46.4321 13.8082 41.888 10.8293 36.0682 9.5978C30.0655 8.32753 23.937 8.85534 17.8065 8.90274"
          stroke-width="3"
          stroke-linecap="round"
        />
      </svg>
    ),
    title: "One Health",
    text: "We are aligned to concept of “One Health”",
  },
];

const ourBrand = [
  {
    img: `${require("./images/products/VETCHOL.png")}`,
  },
  {
    img: `${require("./images/products/vetotm.png")}`,
  },
  {
    img: `${require("./images/products/vetsdic.png")}`,
  },
  {
    img: `${require("./images/products/vetflora.png")}`,
  },

  {
    img: `${require("./images/products/myco.png")}`,
  },
  {
    img: `${require("./images/products/vetmos.png")}`,
  },
  {
    img: `${require("./images/products/myco.png")}`,
  },
  {
    img: `${require("./images/products/zeo.png")}`,
  },
  {
    img: `${require("./images/products/vetsdic.png")}`,
  },
];

const testimonials = [
  {
    text: "I will let my mum know about this, she could really make use of software! Very easy to use. Since I invested in software I made over 100,000 dollars profits. I just can't get enough of software. I want to get a T-Shirt with software on it so I can show it off to everyone.",
    name: "Ronald Richards",
    post: "Gillette",
    img: ``,
  },
  {
    text: "I can't say enough about software. I will refer everyone I know. After using software my business skyrocketed! Not able to tell you how happy I am with software.",
    name: "Floyd Miles",
    post: "Louis Vuitton",
    img: ``,
  },
  {
    text: "I like Infinity Estate more and more each day because it makes my life a lot easier. We can't understand how we've been living without Infinity Estate. Infinity Estate has got everything I need. The service was excellent.",
    name: "Darrell Steward",
    post: "Ferrari",
    img: ``,
  },
];

const OurCapab = () => {
  let arr = [];

  for (let i = 0; i < ourCapabilities.length; i++) {
    arr.push(
      <div
        class="ourCap"
        style={{
          width: "320px",
          height: "372px",
          backgroundImage: `url(${ourCapabilities[i].bgImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100%",
        }}
        data-aos="zoom-in"
      >
        <div class="ourCapDiv">
          <div
            class=" cardNo"
            style={{ height: "50px", position: "relative", top: "30px" }}
          >
            {ourCapabilities[i].no}
            <p class="fs-1" style={{ position: "relative", top: "-65px" }}>
              {i + 1}
            </p>
          </div>
          <div
            class="cardText col-10 m-auto"
            style={{ position: "relative", top: "65px" }}
          >
            <p class="fw-bold fs-5">{ourCapabilities[i].title}</p>
            <p clas="fs-5">{ourCapabilities[i].text}</p>
          </div>
        </div>
      </div>
    );
  }
  return arr;
};

const ourBrandsCard = () => {
  let arr = [];
  for (let i = 0; i < ourBrand.length; i++) {
    arr.push(<img src={ourBrand[i].img} style={{ height: "50px" }} alt="" />);
  }
  return arr;
};

const ourTestimonials = () => {
  let arr = [];
  for (let i = 0; i < testimonials.length; i++) {
    arr.push(
      <div class="testimonialsCard">
        <div class="d-flex gap-1 text-primary">
          <i class="bi bi-star-fill"></i> <i class="bi bi-star-fill"></i>{" "}
          <i class="bi bi-star-fill"></i> <i class="bi bi-star-fill"></i>{" "}
          <i class="bi bi-star-fill"></i>
        </div>
        <div class="text-start">
          <p style={{ fontSize: "16px" }}> {testimonials[i].text}</p>
        </div>
        <div class="text-start">
          <p class="fw-bold" style={{ fontSize: "12px" }}>
            {testimonials[i].name}
          </p>
          <p class="text-secondary" style={{ fontSize: "10px" }}>
            {testimonials[i].post}
          </p>
        </div>
        <div class="text-start d-flex gap-1 align-items-center">
          <div
            class="bg-primary"
            style={{
              fontSize: "10px",
              width: "15px",
              height: "15px",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {" "}
            <span class="text-white">
              <i class="bi bi-hand-thumbs-up-fill"></i>
            </span>{" "}
          </div>
          <span class="fw-bold" style={{ fontSize: "12px" }}>
            Testimonials
          </span>
        </div>
        {/* <div class="testimonialsImg">
          <img src={testimonials[i].img} alt="" />
        </div> */}
      </div>
    );
  }
  return arr;
};
const Home = () => {
  const [selectedCategory, setSelectedCategory] = useState("poultry");
  const [filter, setFilter] = useState(weCare);

  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);
  // useEffect(() => {
  //   const horizontalScroll = () => {
  //     const sticky = document.querySelector(".sticky");
  //     const stickyParent = document.querySelector(".sticky-parent");

  //     const scrollWidth = sticky.scrollWidth;
  //     const verticalScrollHeight =
  //       stickyParent.getBoundingClientRect().height -
  //       sticky.getBoundingClientRect().height;

  //     const stickyPosition = sticky.getBoundingClientRect().top;
  //     if (stickyPosition > 1) {
  //       return;
  //     } else {
  //       const scrolled = stickyParent.getBoundingClientRect().top;
  //       sticky.scrollLeft =
  //         (scrollWidth / verticalScrollHeight) * -scrolled * 0.35;
  //     }
  //   };

  //   document.addEventListener("scroll", horizontalScroll);

  //   return () => {
  //     document.removeEventListener("scroll", horizontalScroll);
  //   };
  // }, []);

  
  useEffect(() => {
    const horizontalScroll = () => {
      const sticky = document.querySelector(".sticky-soil");
      const stickyParent = document.querySelector(".sticky-parent-soil");

      const scrollWidth = sticky.scrollWidth;
      const verticalScrollHeight =
        stickyParent.getBoundingClientRect().height -
        sticky.getBoundingClientRect().height;

      const stickyPosition = sticky.getBoundingClientRect().top;
      if (stickyPosition > 1) {
        return;
      } else {
        const scrolled = stickyParent.getBoundingClientRect().top;
        sticky.scrollLeft =
          (scrollWidth / verticalScrollHeight) * -scrolled * 0.55;
      }
    };

    document.addEventListener("scroll", horizontalScroll);

    return () => {
      document.removeEventListener("scroll", horizontalScroll);
    };
  }, []);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  // const filteredProducts =
  //   selectedCategory === "All"
  //     ? weCare
  //     : weCare.filter((product) => product.key === selectedCategory);

  useEffect(() => {
    const product = weCare.filter((el) => el.id == selectedCategory);
    setFilter(product);
  }, [selectedCategory]);

 

  return (
    <div>
      <div style={{ background: "#FBFFE8" }}>
        <div style={{ height: "50px" }}> </div>
        <div class="row col-12 m-0 p-0 vetminTop">
          <div class="col-md-7 col-12 p-0">
            <br />
            <br />
            <img
              src={require("./images/topPoster3.png")}
              style={{ width: "100%" }}
              alt=""
            />
            <br />
            <br />
            <br />
          </div>
          <div class="col-md-5 col-11 m-auto d-flex align-items-center my-5">
            <div class="col-lg-10 col-12 text-start">
              <p class="display-4 fw-bold" data-aos="fade-up">
                More than just <br /> feed - it's <br /> nutrition you <br />{" "}
                can rely on
              </p>
              <div
                type="button"
                class="blackBtn my-5"
                data-aos="fade-up"
                onClick={() => navigate("/about")}
              >
                <div class="blackText"> Learn More</div>
                <div class="blackBg"> Learn More</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class=" "
        style={{
          backgroundImage: `url(${require("./images/aboutBg.jpg")})`,
          objectFit: "cover",
          backgroundSize: "60% 80% ",
          backgroundRepeat: "no-repeat",
          position: "relative",
          backgroundPosition: "right top",
        }}
      >
        <div class="text-start aboutBg">
          <br />

          <div class="col-md-6 col-11  mt-5 about1" data-aos="zoom-in-up">
            <p class="display-5 fw-semibold mb-3">About Us</p>
            <p class="fs-3" style={{ fontWeight: "400" }}>
              At VetMin Nutrition, our mission is to provide top-tier solutions
              for animal health and enhanced productivity. We are committed to
              delivering quality products that optimize animals' overall
              well-being while driving increased profitability for farmers.
            </p>
          </div>
          <div class="aboutHide"></div>
        </div>
        <div class="about2 d-flex justify-content-end">
          <div
            class=" row col-lg-7 col-md-10 col-12 align-items-center m-0"
            style={{
              background: "#D2EA53",
              borderRadius: "100px 0 0 100px",
            }}
          >
            <div
              class="col-sm-8 col-11  text-start"
              style={{ position: "relative" }}
            >
              <p
                class="col-sm-11   aboutUsButtom"
                style={{ fontSize: "18px", fontWeight: "400" }}
              >
                Through relentless dedication and innovation, we strive to be at
                the forefront of the industry, offering superior nutritional
                solutions that empower our valued customers to achieve
                remarkable results. 
              </p>
              <div
                class="animated"
                style={{ position: "absolute", top: "-60px", left: "20%" }}
              >
                <Hand />
              </div>
            </div>
            <div class="col-sm-4 col-12 text-start  ">
              <div
                type="button"
                class="blackBtn my-2  "
                onClick={() => navigate("./about")}
              >
                <div class="blackText">Learn More</div>
                <div class="blackBg">Learn More</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          background: "#0081F3",
          borderRadius: "0 0 50px 50px",
          position: "relative",
        }}
      >
        <br />
        <br />
        <div
          class="crown animated "
          style={{ position: "absolute", top: "-70px" }}
        >
          <Crown />
        </div>

        <p class="display-5 fw-semibold text-white mb-5" data-aos="fade-up">
          We Care For
        </p>

        <div
          class="weCareOption d-flex align-items-center justify-content-center"
          data-aos="fade-up"
        >
          {/* <div class="weCare" onClick={() => handleCategoryChange("All")}>
            <div
              class="p-3 px-4 weCareIcon rounded-4"
              style={
                selectedCategory == "All"
                  ? { background: "black" }
                  : { background: "#0074DB" }
              }
            >
              <img src={require("./images/others.png")} alt="" />
            </div>
            <div
              class="p-1 weCareText"
              style={
                selectedCategory == "All"
                  ? { color: "white" }
                  : { color: "#69B9FF" }
              }
            >
              <p>Others</p>
            </div>
          </div> */}

          <div class="weCare" onClick={() => handleCategoryChange("poultry")}>
            <div
              class="p-3 px-4 weCareIcon rounded-4"
              style={
                selectedCategory == "poultry"
                  ? { background: "black" }
                  : { background: "#0074DB" }
              }
            >
              <img src={require("./images/poultry.png")} alt="" />
            </div>
            <div
              class="p-1 weCareText"
              style={
                selectedCategory == "poultry"
                  ? { color: "white" }
                  : { color: "#69B9FF" }
              }
            >
              <p>Poultry</p>
            </div>
          </div>

          <div class="weCare" onClick={() => handleCategoryChange("cattle")}>
            <div
              class="p-3 px-4 weCareIcon rounded-4"
              style={
                selectedCategory == "cattle"
                  ? { background: "black" }
                  : { background: "#0074DB" }
              }
            >
              <img src={require("./images/cattle.png")} alt="" />
            </div>
            <div
              class="p-1 weCareText"
              style={
                selectedCategory == "cattle"
                  ? { color: "white" }
                  : { color: "#69B9FF" }
              }
            >
              <p>Cattle</p>
            </div>
          </div>

          <div class="weCare" onClick={() => handleCategoryChange("aqua")}>
            <div
              class="p-3 px-4 weCareIcon rounded-4"
              style={
                selectedCategory == "aqua"
                  ? { background: "black" }
                  : { background: "#0074DB" }
              }
            >
              <img src={require("./images/aqua.png")} alt="" />
            </div>
            <div
              class="p-1 weCareText"
              style={
                selectedCategory == "aqua"
                  ? { color: "white" }
                  : { color: "#69B9FF" }
              }
            >
              <p>Aqua</p>
            </div>
          </div>
        </div>
        <div class="weCareMobile">
          <br />
          <WeCare images={filter} />
          <br />
        </div>

        {/* <div class="weCarePc">
          <div className="sticky-parent">
            <div className="sticky">
              <div className="horizontal">
                {filter.map((el) => {
                  return (
                    <div className="dim">
                      <div
                        class=" col-md-10 col-11 m-auto"
                        style={{ height: "90vh", position: "relative" }}
                      >
                        <div
                          class="bg-light rounded-4 col-12 m-auto p-4"
                          style={{
                            position: "absolute",
                            top: "35%",
                            height: "50vh",
                          }}
                        >
                          <div style={{ height: "20vh" }}></div>
                          <p class="fw-bold fs-2 ">{el.titile}</p>
                          <p
                            class="my-2 fs-5"
                            style={{ fontSize: "18px", fontWeight: "400" }}
                          >
                            {el.text}
                          </p>
                        </div>
                        <div
                          class="col-12 "
                          style={{ position: "absolute", top: "0" }}
                        >
                          <img src={el.img} style={{ width: "100%" }} alt="" />
                        </div>
                        <br />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div> */}
         <div class="productPc">
            <div className="sticky-parent-soil">
              <div className="sticky-soil">
                <div className="horizontal-soil">
                  {filter.map((el) => {
                    return (
                      <div className="product">
                        <div
                          class=" col-md-10 col-11 m-auto productCartMainSoil"
                          style={{ height: "80vh" }}
                        >
                            <div
                              class='bg-light' style={{borderRadius:'25px 25px 0 0 '}}
                          >
                            <div class='col-8 m-auto'>

                            <img
                              src={el.img}
                              style={{ height:'35vh' }}
                              alt=""
                            />
                            </div>
                          </div>
                          <div
                            class="bg-light col-12 m-auto p-4 productCartSoil" style={{borderRadius:'0 0 25px 25px  '}}
                          >
                            
                            {/* <p class="fw-bold fs-2 ">{el.titile}</p> */}
                            <p
                              class="my-2 fs-5"
                              style={{ fontSize: "18px", fontWeight: "400" }}
                            >
                              {el.text}
                            </p>
                            <div
                              type="button"
                              class="blueBtn mt-4 text-end m-auto productCartSoilButton"
                              onClick={() => navigate("/all-products")}
                            >
                              <div class="blueText">Know More</div>
                              <div class="blueBg">Know More</div>
                            </div>
                          </div> 
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
      </div>
      

      <div>
        <p class="display-5 fw-semibold my-5" data-aos="fade-up">
          Our Capabilities
        </p>
        <div class="col-11 m-auto">
          <OurCapabilities data={ourCapabilities} />
        </div>

        {/* <div
          class="col-11 m-auto d-flex  flex-wrap gap-4   "
          style={{ position: "relative", justifyContent: "space-evenly" }}
        >
          {OurCapab()}
          <div
            class="animated"
            style={{ position: "absolute", bottom: "10%", left: "-5%" }}
          >
            <Pig />
          </div>
        </div> */}
      </div>

      <div class="my-5">
        <div
          style={{
            background: "#B0CB1F",
            borderRadius: "150px",
            padding: "15px",
            width: "95%",
            margin: "auto",
          }}
          data-aos="zoom-in"
        >
          <p class="text-black  fw-bold my-4 fs-1">All Products</p>
          <div>
              <OurBrands data={ourBrand}/>
          </div>
          {/* <div
            class="d-flex p-4  gap-5 flex-nowarp     "
            style={{ overflowX: "auto" }}
          >
            {ourBrandsCard()}
          </div> */}
          <br />
        </div>
      </div>

      <div class="row gap-5 col-11 m-auto">
        <div class="col-md-5 col-12">
          <div data-aos="zoom-in" style={{ position: "relative" }}>
            <img
              src={require("./images/iso.png")}
              style={{ width: "100%" }}
              alt=""
            />
            <div
              class="animated"
              style={{ position: "absolute", top: "0", left: "5%" }}
            >
              <ThumsUp />
            </div>
            <div
              class="animated"
              style={{ position: "absolute", bottom: "0", right: "0" }}
            >
              <DrowLine1 />
            </div>
          </div>
        </div>

        <div class="col-md-6 col-12 d-flex align-items-center">
          <div class="text-start">
            <p class="display-5 fw-semibold" data-aos="fade-up">
              Our Certifications
            </p>

            <p
              class="fs-5 my-4"
              style={{ textAlign: "justify" }}
              data-aos="fade-up"
            >
              VetMin proudly holds the ISO 9001:2015 certification, a testament to our
 unwavering commitment to quality. Our dedication to providing top-notch animal nutrition 
solutions, adhering to international standards for excellence in every 
aspect of our operations.
            </p>
            <div
              type="button"
              class="blackBtn"
              data-aos="fade-up"
              onClick={() => navigate("/about")}
            >
              <div class="blackText">Learn More</div>
              <div class="blackBg">Learn More</div>
            </div>
          </div>
        </div>
      </div>

      <div class="testimonials">
        <div
          class="mt-5  "
          // style={{ background: "#B0CB1F", borderRadius: "100px" }}
        >
          <br />
          <br />
          {/* <div
            style={{
              width: "fit-content",
              margin: "auto",
              position: "relative",
            }}
          >
            <p class="display-5 fw-bold " data-aos="fade-up">
              Blog's
            </p>
            <div
              class="animated"
              style={{ position: "absolute", top: "-20px", right: "-100px" }}
            >
              <DrowLine2 />
            </div>
          </div>
          <div class="col-11 m-auto my-5 " data-aos="fade-up">
            <Testimonials data={ourTestimonials()} />
          </div> */}
          {/* <div style={{ height: "200px" }}></div> */}
        </div>
        {/* <div  data-aos="fade-up">
          <UpFooter />
          <br />
          <br />
        </div> */}
        <div>
          <UpFooter />
        </div>
      </div>
    </div>
  );
};

export default Home;
