import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Crown, DrowLine3Black } from "./Svgs";
import UpFooter from "./UpFooter";
import "./about.css";
import { useNavigate } from "react-router-dom";

const About = () => {
  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);

  const [scrollPos, setScrollPos] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPos(window.pageYOffset);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const rotateDeg = scrollPos % 360;

  const imageStyle = {
    transform: `rotate(${rotateDeg}deg)`,
    width: "200px",
    height: "200px",
    margin: "auto",
  };

  const navigate = useNavigate();
  return (
    <div>
      <div style={{ background: "#FBFFE8" }}>
        <div style={{ height: "100px" }}></div>
        <br />
        <div data-aos="zoom-in">
          <p class="display-4 fw-bold"> About Us </p>
          <p style={{ fontSize: "18px" }}>We care for them just as you do.</p>
          <div
            type="button"
            class="blueBtn mt-4 text-end m-auto"
            onClick={() => navigate("/contact")}
          >
            <div class="blueText">Contact Us</div>
            <div class="blueBg">Contact Us</div>
          </div>
        </div>
        <div class="row col-11 m-auto mt-5 gap-5 justify-content-between align-items-center">
          <div class="col-md-6 col-12" data-aos="fade-up">
            <p class="fs-3 tJustify">
              At VetMin Nutrition, our mission is to provide top-tier solutions
              for animal health and enhanced productivity. We are committed to
              delivering quality products that optimize animals' overall
              well-being while driving increased profitability for farmers.
            </p>
          </div>
          <div class="col-md-5 col-12">
            <div data-aos="zoom-in">
              <img
                src={require("./images/aboutGroup.png")}
                style={{ width: "100%" }}
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="col-11 m-auto mt-5 gap-md-0 gap-5 row justify-content-between align-items-center">
          <div class="col-md-3 col-12">
            <div
              style={{
                position: "relative",
                height: "200px",
                width: "200px",
                margin: "auto",
              }}
            >
              <div style={{ position: "relative", top: "0" }}>
                <img
                  src={require("./images/circle1.png")}
                  style={imageStyle}
                  alt=""
                />
              </div>
              <div
                style={{ position: "absolute", top: "-30px", left: "-30px" }}
              >
                <img
                  src={require("./images/circle2.png")}
                  // style={imageStyle}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="col-md-9 col-12" data-aos="fade-up">
            <p class="fs-3 tJustify">
              Through relentless dedication and innovation, we strive to be at
              the forefront of the industry, offering superior nutritional
              solutions that empower our valued customers to achieve remarkable
              results.
            </p>
          </div>
        </div>
        <br />
      </div>

      <div
        style={{
          background: "linear-gradient(#FBFFE8 50%, #ffffff 50%)",
          position: "relative",
        }}
      >
        <br />
        <div
          class=" animated "
          style={{ position: "absolute", top: "0px", right: "10%" }}
        >
          <Crown />
        </div>

        <div
          class="col-11 m-auto rounded-5 my-5"
          style={{ background: "#0081F3" }}
        >
          <br />
          <p class="fs-1 fw-bold text-white mt-5 ">The values that define us</p>
          <div class="aboutValueMain my-5 col-11 m-auto">
            <div
              class="aboutValue1 fs-5 fw-semibold"
              style={{ borderRadius: "50%" }}
              data-aos="zoom-in"
            >
              Dedication
            </div>
            <div
              class="aboutValue2 fs-5 fw-semibold"
              style={{ borderRadius: "50%" }}
              data-aos="zoom-in"
            >
              Innovation
            </div>
            <div
              class="aboutValue3 fs-5 fw-semibold"
              style={{ borderRadius: "50%" }}
              data-aos="zoom-in"
            >
              Ethics
            </div>
            <div
              class="aboutValue4 fs-5 fw-semibold"
              style={{ borderRadius: "50%" }}
              data-aos="zoom-in"
            >
              Profitability
            </div>
            <div
              class="aboutValue5 fs-5 fw-semibold"
              style={{ borderRadius: "50%" }}
              data-aos="zoom-in"
            >
              Partnership
            </div>
            <div
              class="aboutValue6 fs-5 fw-semibold"
              style={{ borderRadius: "50%" }}
              data-aos="zoom-in"
            >
              Quality
            </div>
            <div
              class="aboutValue7 fs-5 fw-semibold"
              style={{ borderRadius: "50%" }}
              data-aos="zoom-in"
            >
              Science
            </div>
            <div
              class="aboutValue8 fs-5 fw-semibold"
              style={{ borderRadius: "50%" }}
              data-aos="zoom-in"
            >
              Affordability
            </div>
            <div
              class="aboutValue9 fs-5 fw-semibold"
              style={{ borderRadius: "50%" }}
              data-aos="zoom-in"
            >
              Customer <br /> Service
            </div>
            <div
              class="aboutValue10 fs-5 fw-semibold"
              style={{ borderRadius: "50%" }}
              data-aos="zoom-in"
            >
              Health
            </div>
          </div>
        </div>
      </div>

      <div class="col-11 m-auto mt-5">
        <div class="text-start" data-aos="fade-up">
          <p class="display-3 fw-bold ">
            What's Most <br />
            important to us. <br />
            <span>
              <DrowLine3Black />
            </span>
          </p>
        </div>
        <div class="row">
          <div class="col-md-5 col-12"></div>
          <div class="col-md-7 col-12 row">
            <div class="col-sm-6 col-12 text-start my-3" data-aos="fade-up">
              <img
                src={require("./images/icon5.png")}
                style={{ width: "90px" }}
                alt=""
              />
              <p class="fs-1 fw-semibold">Mission</p>
              <p>
                To provide top-quality and affordable nutrition products
                scientifically proven to enhance livestock health and farmer
                profitability in India.
              </p>
            </div>
            <div class="col-sm-6 col-12 text-start my-3" data-aos="fade-up">
              <img
                src={require("./images/icon6.png")}
                style={{ width: "90px" }}
                alt=""
              />
              <p class="fs-1 fw-semibold">Vision</p>
              <p>
                To be a trusted partner, prioritizing client needs, and
                improving health outcomes for animals while increasing farmers'
                profitability.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        class="mt-5"
        style={{
          background: "linear-gradient(#ffffff 50%,black 25% ,black 50%)",
        }}
      >
        <UpFooter />
      </div>
    </div>
  );
};

export default About;
