import React from "react";
import "./footer.css";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div
      class="bg-black text-white"
      style={{ position: "relative", top: "-5px" }}
    >
      <div style={{ height: "70px" }}></div>
      <div class="col-10 row m-auto gap-4 ">
        <div class="col-md-6 col-12 row gap-4">
          <div class="col-sm-7 col-12 text-start">
            
            <p>Copyright <br /> © 2023 Vetmin Nutritions Pvt Ltd <br />  All Rights Reserved. <br /> <br />  Developed by : <span style={{color:'#80C04D',fontWeight:'bold'}}> Hi Lab Solution </span><a href="https://hi-labsolution.com/" target="_blank"><img src="https://hi-labsolution.com/hlogo.png" alt="Hi-Lab Solution"/></a></p>

            {/* <p class="mt-3" style={{ fontSize: "16px" }}>
              Privacy Policy
            </p>
            <p style={{ fontSize: "16px" }}>Terms & Conditions</p> */}
          </div>
          <div class="col-sm-4 col-12 text-start">
            <p
              style={{ fontSize: "18px", cursor: "pointer" }}
              onClick={() => navigate("/")}
            >
              Home
            </p>
            <p
              style={{ fontSize: "18px", cursor: "pointer" }}
              onClick={() => navigate("/about")}
            >
              About Us
            </p>
            <p
              style={{ fontSize: "18px", cursor: "pointer" }}
              onClick={() => navigate("/animal-nutrition")}
            >
              Animal Nutrition
            </p>
            <p
              style={{ fontSize: "18px", cursor: "pointer" }}
              onClick={() => navigate("/all-products")}
            >
              All Products
            </p>
           
            <p
              style={{ fontSize: "18px", cursor: "pointer" }}
              onClick={() => navigate("/contact")}
            >
              Contact Us
            </p>
          </div>
        </div>

        <div class="col-md-5 col-12 row m-auto">
          <div class="d-flex gap-2 flex-wrap justify-content-center text-black">
            {/* <div class="socialIcon">
              <FaInstagram class="iconFont fs-4" />
            </div>
            <div class="socialIcon">
              <FaFacebookF class="iconFont fs-4" />
            </div>
            <div class="socialIcon">
              <FaTwitter class="iconFont fs-4" />
            </div>
            <div class="socialIcon">
              <FaYoutube class="iconFont fs-4" />
            </div> */}
              <a href="https://www.linkedin.com/company/vetmin-nutrition-pvt-ltd/" target="_blanck"> 
            <div class="socialIcon">
                <FaLinkedin class="iconFont fs-4" />
            </div>
              </a>
          </div>
        </div>
      </div>
      <br />
    </div>
  );
};

export default Footer;
