import React, { useEffect, useState } from "react";
import "./contact.css";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { DrowLine4 } from "./Svgs";
import AOS from "aos";
import "aos/dist/aos.css";
import Swal from "sweetalert2";
import axios from "axios";

const Contact = ({ data }) => {

  const [contact,setContact]= useState({})

  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);

  const contactInfo = (e)=>{
    const {name,value}= e.target;
    setContact({...contact,[name]:value})
}


const sendInfo =()=>{
  if(contact?.name && contact?.name != "" &&
  contact?.phone && contact?.phone != "" &&
  contact?.email && contact?.email != "" &&
  contact?.message && contact?.message != ""){
  
          axios.post(`https://hi-labsolution.net/api/vetmin-contact.php`,contact)
          .then((res)=>{
              // console.log(res);
              if(res.data.responseStatus == true){
                  Swal.fire({
                    position: "center",
                    icon: "success",
                    title: `${res.data.responseMessage}`,
                    showConfirmButton: false,
                    timer: 2000,
                  })
                  setContact({
                      "name" : '',
                      'phone':'',
                      'email': '',
                      'message':''
                  })
              }else{
                  Swal.fire({
                      position: "center",
                      icon: "error",
                      title: `${res.data.responseMessage}`,
                      showConfirmButton: false,
                      timer: 2000,
                    })
              }
          }).catch((err)=>{
              console.log(err);
          })
      
  }else{
      Swal.fire({
          position: "center",
          icon: "error",
          title: `Fill all filds`,
          showConfirmButton: false,
          timer: 2000,
        })
  }
}
  return (
    <div>
      <div style={{ background: "#FBFFE8" }}>
        <div style={{ height: "100px" }}> </div>

        <br />
        <div data-aos="zoom-in">
          <p class="display-4 fw-bold"> Connect with us </p>
          <p style={{ fontSize: "18px" }}>Let us know how we can help</p>
          <div
            type="button"
            class="blueBtn mt-4 text-end m-auto"
            onClick={() => data(true)}
          >
            <div class="blueText">Enquiry</div>
            <div class="blueBg">Enquiry</div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <div class="d-flex  gap-5 justify-content-around  col-11 m-auto  flex-wrap ">
          <div class="contactCart text-start " data-aos="fade-up">
            <p class="fw-bold" style={{ fontSize: "25px" }}>
              Call Us
            </p>
            <p class="mt-2 fw-semibold" style={{ fontSize: "" }}>
            +91 81608 49455</p>
            {/* <p style={{ fontSize: "18px" }}>+91 81608 49455</p> */}
          </div>
          <div class="contactCart text-start " data-aos="fade-up">
            <p class="fw-bold" style={{ fontSize: "25px" }}>
              Write Us
            </p>
            <p class="mt-2 fw-semibold" style={{ fontSize: "" }}>
            info@vetmin.in 
            </p>
            {/* <p style={{ fontSize: "18px" }}>vetmin@gmail.com</p> */}
          </div>
          <div class="contactCart text-start " data-aos="fade-up">
            <p class="fw-bold" style={{ fontSize: "25px" }}>
              Visit Us
            </p>
            <p class="mt-2" style={{ fontSize: "" }}>
             <span class='fw-semibold'>Vetmin Nutritions Pvt Ltd</span>  <br />
             
Behind Hotel 2000, Survey No 266/2, 
National Highway No 8, 
Vaghasi, Anand (Gujarat)
            </p>
          </div>
          <div class="contactCart text-start " data-aos="fade-up">
            <p class="fw-bold" style={{ fontSize: "25px" }}>
              Follow Us
            </p>
            <div class=" col-12 row m-auto mt-2">
              <div class="d-flex gap-2 flex-wrap justify-content-center text-black">
                {/* <div class="cSocialIcon">
                  <FaInstagram class="iconFont" />
                </div>
                <div class="cSocialIcon">
                  <FaFacebookF class="iconFont" />
                </div>
                <div class="cSocialIcon">
                  <FaTwitter class="iconFont" />
                </div>
                <div class="cSocialIcon">
                  <FaYoutube class="iconFont" />
                </div> */}
                  <a href="https://www.linkedin.com/company/vetmin-nutrition-pvt-ltd/" target="_blanck">
                <div class="cSocialIcon">

                  <FaLinkedin class="iconFont" />
                </div>
                  </a>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
      </div>

      <div
        style={{
          background: "linear-gradient(#FBFFE8 25%,black 25% ,black 75%)",
        }}
      >
        <div
          class="col-11 m-auto p-3 rounded-5"
          style={{ background: "#0081F3" }}
          data-aos="zoom-in-up"
        >
          <p
            class="display-3 fw-bold text-white mx-4   text-start"
            data-aos="fade-up"
          >
            Get to know us better,
            <br /> You People!{" "}
            <span class="animated">
              <DrowLine4 />
            </span>{" "}
          </p>

          <div class="col-12 row m-0 mt-5">
            <div class="col-md-6 col-12  ">
              <div class="col-lg-7 col-md-10 m-auto" data-aos="zoom-in">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4634.978879376119!2d72.98191930494096!3d22.543687784199594!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e4c0780981455%3A0xf138aed2699c1d25!2sAnand%20-%20Vaghasi%20Rd%2C%20Anand%2C%20Gujarat!5e0!3m2!1sen!2sin!4v1695300921348!5m2!1sen!2sin"
                  width="100%"
                  height="350"
                  style={{ borderRadius: "25px" }}
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
            <div class="col-md-6 col-12 ">
              <input
                type="text"
                class="form-control rounded-5 mt-3 text-white"
                style={{ background: "transparent" }}
                placeholder="Full Name"
                data-aos="fade-up"
                name ='name'
                value= {contact?.name}
                onChange={contactInfo}
              />
              
              <input
                type="text"
                class="form-control rounded-5 mt-3 text-white"
                style={{ background: "transparent" }}
                placeholder="Phone Number"
                data-aos="fade-up"
                name ='phone'
                value= {contact?.phone}
                
                onChange={contactInfo}
              />
              <input
                type="text"
                class="form-control rounded-5 mt-3 text-white"
                style={{ background: "transparent" }}
                placeholder="Email"
                data-aos="fade-up"
                name ='email'
                value= {contact?.email}
                
                onChange={contactInfo}
              />
              <textarea
                type="text"
                class="form-control rounded-5 mt-3 text-white"
                style={{ background: "transparent" }}
                placeholder="Message"
                data-aos="fade-up"
                name ='message'
                value= {contact?.message}
                
                onChange={contactInfo}
              />

              <div class="d-flex justify-content-end " data-aos="fade-up">
                <div type="button" class="whiteBtn my-4 text-end" onClick={sendInfo}>
                  <div class="whiteText">Submit</div>
                  <div class="whiteBg">Submit</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
      </div>
    </div>
  );
};

export default Contact;
