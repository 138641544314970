import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { DrowLine2 } from "./Svgs";
import { Testimonials } from "./MultiCarousel";
import UpFooter from "./UpFooter";
import { useNavigate } from "react-router-dom";
import "./contact.css";
import SoilCirclemain from "./SoilCirclemain";

const testimonials = [
  {
    text: "I will let my mum know about this, she could really make use of software! Very easy to use. Since I invested in software I made over 100,000 dollars profits. I just can't get enough of software. I want to get a T-Shirt with software on it so I can show it off to everyone.",
    name: "Ronald Richards",
    post: "Gillette",
    img: ` `,
  },
  {
    text: "I can't say enough about software. I will refer everyone I know. After using software my business skyrocketed! Not able to tell you how happy I am with software.",
    name: "Floyd Miles",
    post: "Louis Vuitton",
    img: ` `,
  },
  {
    text: "I like Infinity Estate more and more each day because it makes my life a lot easier. We can't understand how we've been living without Infinity Estate. Infinity Estate has got everything I need. The service was excellent.",
    name: "Darrell Steward",
    post: "Ferrari",
    img: ` `,
  },
];

const ourTestimonials = () => {
  let arr = [];
  for (let i = 0; i < testimonials.length; i++) {
    arr.push(
      <div class="testimonialsCard">
        <div class="d-flex gap-1 text-primary">
          <i class="bi bi-star-fill"></i> <i class="bi bi-star-fill"></i>{" "}
          <i class="bi bi-star-fill"></i> <i class="bi bi-star-fill"></i>{" "}
          <i class="bi bi-star-fill"></i>
        </div>
        <div class="text-start">
          <p style={{ fontSize: "16px" }}> {testimonials[i].text}</p>
        </div>
        <div class="text-start">
          <p class="fw-bold" style={{ fontSize: "12px" }}>
            {testimonials[i].name}
          </p>
          <p class="text-secondary" style={{ fontSize: "10px" }}>
            {testimonials[i].post}
          </p>
        </div>
        <div class="text-start d-flex gap-1 align-items-center">
          <div
            class="bg-primary"
            style={{
              fontSize: "10px",
              width: "15px",
              height: "15px",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {" "}
            <span class="text-white">
              <i class="bi bi-hand-thumbs-up-fill"></i>
            </span>{" "}
          </div>
          <span class="fw-bold" style={{ fontSize: "12px" }}>
            Testimonials
          </span>
        </div>
        {/* <div class="testimonialsImg">
          <img src={testimonials[i].img} alt="" />
        </div> */}
      </div>
    );
  }
  return arr;
};

const CropScience = () => {
  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);
  const navigate = useNavigate();

  const [scrollPos, setScrollPos] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPos(window.pageYOffset);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const rotateDeg = scrollPos % 360;

  const imageStyle = {
    transform: `rotate(${rotateDeg}deg)`,
    width: "100%",
  };
  return (
    <div class="cropScience">
      <div style={{ background: "#FBFFE8" }}>
        <div style={{ height: "100px" }}></div>
        <div data-aos="zoom-in">
          <p class="display-4 fw-bold"> All Products </p>
          <p style={{ fontSize: "18px" }}>
            Elevating domestic animal nutrition to foster health, happiness,{" "}
            <br /> and vitality in our beloved companions
          </p>
          <div
            type="button"
            class="blueBtn mt-4 text-end m-auto"
            onClick={() => navigate("/contact")}
          >
            <div class="blueText">Contact Us</div>
            <div class="blueBg">Contact Us</div>
          </div>
          <br />
          <br />
        </div>
      </div>
      <div style={{ background: "linear-gradient(#FBFFE8 50% ,white 50%)" }}>
        <div
          class="  col-11 m-auto"
          style={{
            height: "70vh",
          }}
          data-aos="zoom-in"
        >
          <img
            src={require("./images/cropPoster.jpg")}
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
            alt=""
          />
        </div>
      </div>

      {/* <div class="row col-11 m-auto justify-content-between mt-5 gap-3">
        <div class="col-md-5 col-11 p-0" data-aos="fade-up">
          <p class="fs-3 fw-semibold tJustify">
            Unlocking Crop Potential: Natural, Proven Solutions for Enhanced
            Health and Productivity.
          </p>
        </div>
        <div class="col-md-5 col-11 p-0" data-aos="fade-up">
          <p class="fs-4 tJustify">
            At every production stage, Vetmin Crop Science offers natural-based
            solutions to meet your crops' needs.
          </p>
        </div>
      </div> */}

      {/* <div class="col-12 row mt-5">
        <div class="col-md-5 col-11 text-start ">
          <div class="col-10 m-auto " data-aos="fade-up">
            <p class="fs-1 fw-bold">Soil</p>
            <p class="tJustify fs-5">
              Rich and fertile soil is vital for agricultural production,
              efficiently feeding the growing population with balanced
              structure, organic content, biodiversity, and nutrient
              availability.
            </p>
            <div
              type="button"
              class="blackBtn my-4 text-end "
              onClick={() => navigate("/soils")}
            >
              <div class="blackText">Know More</div>
              <div class="blackBg">Know More</div>
            </div>
          </div>
        </div>
        <div class="col-md-7 col-12 row justify-content-between m-0 p-0 gap-sm-0 gap-5 " >
          <div class="col-md-5 col-sm-6 col-12" data-aos="zoom-in">
            <img
              src={require("./images/soil.png")}
              style={{ width: "100%" }}
              alt=""
            />
          </div>
          <div class="col-md-5 col-sm-6 col-12 m-0 p-0 " data-aos="zoom-in">
            <div class='col-sm-12 col-11 m-auto' style={{position:'relative'}}> 
            <img class='cercleSoil '
                src={require("./images/cercul.png")}
                style={imageStyle}
                alt=""
              />
            </div>
          </div>
        </div>
      </div> */}
      <div class="" style={{ overflow: "hidden" }}>
        <SoilCirclemain />
      </div>

      <div class="testimonials">
        <div
          class="mt-5  "
          // style={{ background: "#B0CB1F", borderRadius: "100px" }}
        >
          <br />
          <br />
          {/* <div
            style={{
              width: "fit-content",
              margin: "auto",
              position: "relative",
            }}
          >
            <p class="display-5 fw-bold " data-aos="fade-up">
              Blog's
            </p>
            <div
              class="animated"
              style={{ position: "absolute", top: "-20px", right: "-100px" }}
            >
              <DrowLine2 />
            </div>
          </div>
          <div class="col-11 m-auto my-5 " data-aos="fade-up">
            <Testimonials data={ourTestimonials()} />
          </div> */}
          {/* <div style={{ height: "200px" }}></div> */}
        </div>
        <div>
          <UpFooter />
        </div>
      </div>
    </div>
  );
};

export default CropScience;
