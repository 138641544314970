import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Testimonials, WeCare } from "./MultiCarousel";
import { Crown, DrowLine2 } from "./Svgs";
import "./soilPage.css";
import UpFooter from "./UpFooter";

const weCare = [
  {
    titile: "VetOTM",
    id: "poultry",
    text: "Give the litter box a little love, with quick-clumping organic tofu pellets.",
    img: `${require("./images/products/vetotm.png")}`,
  },
  {
    titile: "VetChol",
    id: "poultry",
    text: "Give the litter box a little love, with quick-clumping organic tofu pellets.",
    img: `${require("./images/products/vetchol2.png")}`,
  },
  {
    titile: "VetMOS",
    id: "poultry",
    text: "Give the litter box a little love, with quick-clumping organic tofu pellets.",
    img: `${require("./images/products/vetmos.png")}`,
  },
  {
    titile: "Vet SDIC",
    id: "poultry",
    text: "Give the litter box a little love, with quick-clumping organic tofu pellets.",
    img: `${require("./images/products/vetsdic2.png")}`,
  },
  {
    titile: "Vet Bind",
    id: "poultry",
    text: "Give the litter box a little love, with quick-clumping organic tofu pellets.",
    img: `${require("./images/products/mycoNewlogo2.png")}`,
  },
  {
    titile: "Vet Flora",
    id: "poultry",
    text: "Give the litter box a little love, with quick-clumping organic tofu pellets.",
    img: `${require("./images/products/vetflora.png")}`,
  },
  {
    titile: "Vet Bind",
    id: "cattle",
    text: "Give the litter box a little love, with quick-clumping organic tofu pellets.",
    img: `${require("./images/products/mycoNewlogo2.png")}`,
  },
  {
    titile: "Toxin Binder",
    id: "cattle",
    text: "Give the litter box a little love, with quick-clumping organic tofu pellets.",
    img: `${require("./images/products/mycoNewlogo2.png")}`,
  },
  {
    titile: "Toxin Binder",
    id: "aqua",
    text: "Give the litter box a little love, with quick-clumping organic tofu pellets.",
    img: `${require("./images/products/mycoNewlogo2.png")}`,
  },
  {
    titile: "Vet SDIC",
    id: "aqua",
    text: "Give the litter box a little love, with quick-clumping organic tofu pellets.",
    img: `${require("./images/products/vetsdic2.png")}`,
  },
  {
    titile: "VetZeo",
    id: "aqua",
    text: "Give the litter box a little love, with quick-clumping organic tofu pellets.",
    img: `${require("./images/products/mycoNewlogo2.png")}`,
  },
];

const weCareSlider = () => {
  let arr = [];
  for (let i = 0; i < weCare.length; i++) {
    arr.push(
      <div
        class="col-lg-9 col-md-10 col-11 m-auto"
        style={{ height: "650px", position: "relative" }}
      >
        <div
          class="bg-light rounded-4 col-12 m-auto p-4"
          style={{ position: "absolute", top: "40%" }}
        >
          <div style={{ height: "80px" }}></div>
          <p class="fw-bold " style={{ fontSize: "25px" }}>
            {weCare[i].titile}
          </p>
          <p class=" my-2" style={{ fontSize: "18px", fontWeight: "400" }}>
            {weCare[i].text}
          </p>
          <div type="button" class="blueBtn mt-4 text-end m-auto  ">
            <div class="blueText">Contact Us</div>
            <div class="blueBg">Contact Us</div>
          </div>
        </div>
        <div class="col-12 " style={{ position: "absolute", top: "10%" }}>
          <img src={weCare[i].img} style={{ width: "350px" }} alt="" />
        </div>
      </div>
    );
  }
  return arr;
};

const testimonials = [
  {
    text: "I will let my mum know about this, she could really make use of software! Very easy to use. Since I invested in software I made over 100,000 dollars profits. I just can't get enough of software. I want to get a T-Shirt with software on it so I can show it off to everyone.",
    name: "Ronald Richards",
    post: "Gillette",
    img: ` `,
  },
  {
    text: "I can't say enough about software. I will refer everyone I know. After using software my business skyrocketed! Not able to tell you how happy I am with software.",
    name: "Floyd Miles",
    post: "Louis Vuitton",
    img: ` `,
  },
  {
    text: "I like Infinity Estate more and more each day because it makes my life a lot easier. We can't understand how we've been living without Infinity Estate. Infinity Estate has got everything I need. The service was excellent.",
    name: "Darrell Steward",
    post: "Ferrari",
    img: ` `,
  },
];
const ourTestimonials = () => {
  let arr = [];
  for (let i = 0; i < testimonials.length; i++) {
    arr.push(
      <div class="testimonialsCard">
        <div class="d-flex gap-1 text-primary">
          <i class="bi bi-star-fill"></i> <i class="bi bi-star-fill"></i>{" "}
          <i class="bi bi-star-fill"></i> <i class="bi bi-star-fill"></i>{" "}
          <i class="bi bi-star-fill"></i>
        </div>
        <div class="text-start">
          <p style={{ fontSize: "16px" }}> {testimonials[i].text}</p>
        </div>
        <div class="text-start">
          <p class="fw-bold" style={{ fontSize: "12px" }}>
            {testimonials[i].name}
          </p>
          <p class="text-secondary" style={{ fontSize: "10px" }}>
            {testimonials[i].post}
          </p>
        </div>
        <div class="text-start d-flex gap-1 align-items-center">
          <div
            class="bg-primary"
            style={{
              fontSize: "10px",
              width: "15px",
              height: "15px",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {" "}
            <span class="text-white">
              <i class="bi bi-hand-thumbs-up-fill"></i>
            </span>{" "}
          </div>
          <span class="fw-bold" style={{ fontSize: "12px" }}>
            Testimonials
          </span>
        </div>
        {/* <div class="testimonialsImg">
          <img src={testimonials[i].img} alt="" />
        </div> */}
      </div>
    );
  }
  return arr;
};

const SoilPage = () => {
  useEffect(() => {
    AOS.init({ duration: 1500 });
  });

  useEffect(() => {
    const horizontalScroll = () => {
      const sticky = document.querySelector(".sticky-soil");
      const stickyParent = document.querySelector(".sticky-parent-soil");

      const scrollWidth = sticky.scrollWidth;
      const verticalScrollHeight =
        stickyParent.getBoundingClientRect().height -
        sticky.getBoundingClientRect().height;

      const stickyPosition = sticky.getBoundingClientRect().top;
      if (stickyPosition > 1) {
        return;
      } else {
        const scrolled = stickyParent.getBoundingClientRect().top;
        sticky.scrollLeft =
          (scrollWidth / verticalScrollHeight) * -scrolled * 0.35;
      }
    };

    document.addEventListener("scroll", horizontalScroll);

    return () => {
      document.removeEventListener("scroll", horizontalScroll);
    };
  }, []);
  return (
    <div>
      <div style={{ background: "#FBFFE8" }}>
        <div style={{ height: "100px" }}></div>
        <div class="col-11 m-auto row justify-content-between align-items-center gap-sm-0 gap-4">
          <div class="col-md-6 col-12">
            <div class="col-11">
              <img
                src={require("./images/soil2.png")}
                style={{ width: "100%" }}
                alt=""
              />
            </div>
          </div>
          <div class="col-md-6 col-12 text-start">
            <p class="fs-1 fw-semibold"> Soil</p>
            <p class="fs-5 tJustify fw-semibold ">
              Rich and fertile soil is vital for agricultural production,
              efficiently feeding the growing population with balanced
              structure, organic content, biodiversity, and nutrient
              availability.
            </p>
            <p class="fs-5 tJustify fw-semibold mt-3">
              Therefore, maintaining a balanced, healthy soil profile lays the
              foundation for growing productive, nutrient-rich crops.
            </p>
            <div class="d-flex gap-3 align-items-center">
              <div type="button" class="blackBtn my-4 text-end">
                <div class="blackText">Learn More</div>
                <div class="blackBg">Learn More</div>
              </div>
              <a href="" class="fs-5 text-black">
                Download PDF
              </a>
            </div>
          </div>

          <div class="  m-auto">
            <p class="fs-2 fw-semibold text-start my-5">
              How do healthy soils benefit <br />
              you and your farm?
            </p>
            <div class="col-12 row justify-content-between gap-sm-0 gap-4 ">
              <div class="col-lg-4 col-sm-6 col-12   text-start">
                <div class="col-sm-11 col-12 m-auto">
                  <img
                    src={require("./images/icon2.png")}
                    style={{ width: "60px", height: "60px" }}
                    alt=""
                  />
                  <p class="fs-4 fw-bold my-3">
                    Reduce the impact of stress on your crops
                  </p>
                  <p class="tJustify fs-5 fw-semibold">
                    During the season, plant stress events — such as heat,
                    frost, drought and disease — strain plant resources and
                    greatly reduce potential end-of-season harvest yields.
                    Healthy, productive soils can offset the worst of these
                    stresses, helping maintain optimal harvest yields and
                    business profitability.
                  </p>
                </div>
              </div>
              <div class="col-lg-4 col-sm-6 col-12  text-start">
                <div class="col-sm-11 col-12 m-auto">
                  <img
                    src={require("./images/icon3.png")}
                    style={{ width: "60px", height: "60px" }}
                    alt=""
                  />
                  <p class="fs-4 fw-bold my-3">
                    Increased production with the use of fewer inputs
                  </p>
                  <p class="tJustify fs-5 fw-semibold mt-3">
                    Well-balanced soils that are rich in organic matter and
                    beneficial microbes naturally supply much of the nutrition
                    and other requirements that would otherwise need to be
                    supplemented by the grower. Reducing the amount of crop
                    inputs required means money saved.
                  </p>
                </div>
              </div>
              <div class="col-lg-4 col-sm-6 col-12  text-start">
                <div class="col-sm-11 col-12 m-auto">
                  <img
                    src={require("./images/icon1.png")}
                    style={{ width: "60px", height: "60px" }}
                    alt=""
                  />
                  <p class="fs-4 fw-bold my-3">
                    Sustainable approach for long-term profitability
                  </p>
                  <p class="tJustify fs-5 fw-semibold  ">
                    Healthy soils are not only more environmentally sustainable
                    but also represent a valuable, revenue-generating asset for
                    growers, their businesses and generations yet to come.
                  </p>
                </div>
              </div>
            </div>
            <br />
          </div>
        </div>
      </div>

      <div
        style={{
          background: "#0081F3",
          borderRadius: " 50px 50px 0 0",
          position: "relative",
        }}
      >
        <br />
        <br />
        <p class="display-5 fw-semibold text-white  " data-aos="fade-up">
          Products
        </p>

        <div class="productMobile">
          <WeCare images={weCareSlider()} />
        </div>

        <div class="productPc">
          <div className="sticky-parent-soil">
            <div className="sticky-soil">
              <div className="horizontal-soil">
                {weCare.map((el) => {
                  return (
                    <div className="product">
                      <div
                        class=" col-md-10 col-11 m-auto productCartMainSoil"
                        style={{ height: "90vh", position: "relative" }}
                      >
                        <div
                          class="bg-light rounded-4 col-12 m-auto p-4 productCartSoil"
                          style={{ position: "absolute", top: "35%" }}
                        >
                          <div style={{ height: "20vh" }}></div>
                          <p class="fw-bold fs-2 ">{el.titile}</p>
                          <p
                            class="my-2 fs-5"
                            style={{ fontSize: "18px", fontWeight: "400" }}
                          >
                            {el.text}
                          </p>
                          <div
                            type="button"
                            class="blueBtn mt-4 text-end m-auto productCartSoilButton"
                          >
                            <div class="blueText">Contact Us</div>
                            <div class="blueBg">Contact Us</div>
                          </div>
                        </div>
                        <div
                          class="col-12 "
                          style={{ position: "absolute", top: "0" }}
                        >
                          <img src={el.img} style={{ width: "100%" }} alt="" />
                        </div>
                        <br />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="testimonials"
        style={{ background: "linear-gradient(#0081F3 20% ,black 50%)" }}
      >
        <br />
        <br />
        <div
          class="  "
          style={{ background: "#B0CB1F", borderRadius: "100px" }}
        >
          <div
            class=""
            style={{
              width: "fit-content",
              margin: "auto",
              position: "relative",
            }}
          >
            <br />
            <br />
            <p class="display-5 fw-bold " data-aos="fade-up">
              Blog's
            </p>
            <div
              class="animated"
              style={{ position: "absolute", top: "40px", right: "-100px" }}
            >
              <DrowLine2 />
            </div>
          </div>
          <div class="col-11 m-auto my-5 " data-aos="fade-up">
            <Testimonials data={ourTestimonials()} />
          </div>
          <div style={{ height: "200px" }}></div>
        </div>
        <div style={{ marginTop: "-150px" }} data-aos="fade-up">
          <UpFooter />
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};

export default SoilPage;
