import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./animalNutrition.css";
import { useNavigate } from "react-router-dom";
import { WeCare } from "./MultiCarousel";
import UpFooter from "./UpFooter";
import { Link } from "react-scroll";

const ourCapabilities = [
  {
    bgImage: `${require("./images/Group1.png")}`,
    title: "Calf",
  },
  {
    bgImage: `${require("./images/Group2.png")}`,
    title: "Heifer",
  },
  {
    bgImage: `${require("./images/Group3.png")}`,
    title: "Lactating cow",
  },
  {
    bgImage: `${require("./images/Group4.png")}`,
    title: "Dry Cow",
  },
];

const OurCapab = () => {
  let arr = [];

  for (let i = 0; i < ourCapabilities.length; i++) {
    arr.push(
      <div
        class="animalCap"
        style={{
          width: "300px",
          height: "300px",
          backgroundImage: `url(${ourCapabilities[i].bgImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100%",
        }}
        data-aos="zoom-in"
      >
        <div class="animalCapDiv d-flex align-items-center justify-content-center">
          <div class="cardText col-10 m-auto d-flex align-items-center justify-content-center">
            <p class="fw-bold fs-5">{ourCapabilities[i].title}</p>
          </div>
        </div>
      </div>
    );
  }
  return arr;
};

const weCare = [
  {
    titile: "VetChol",
    id: "poultry",
    text: "Unlocking Optimal Health and Nervous System Vitality!",
    img: `${require("./images/products/VETCHOL.png")}`,
  },
  {
    titile: "VetOTM",
    id: "poultry",
    text: "Organic trace mineral",
    img: `${require("./images/products/vetotm.png")}`,
  },
  {
    titile: "Vet SDIC",
    id: "poultry",
    text: "Purify with Power: Ultimate Defense Against Waterborne Threats! ",
    img: `${require("./images/products/vetsdic.png")}`,
  },
  {
    titile: "Vet Flora",
    id: "poultry",
    text: "Elevate Gut Health, Empower Wellness!",
    img: `${require("./images/products/vetflora.png")}`,
  },
  {
    titile: "Mycotoxcease",
    id: "poultry",
    text: "Our Shield Against Harmful Mycotoxins",
    img: `${require("./images/products/myco.png")}`,
  },
  {
    titile: "VetMOS",
    id: "poultry",
    text: "For Comprehensive toxin management",
    img: `${require("./images/products/vetmos.png")}`,
  },
  // {
  //   titile: "Vet Bind",
  //   id: "poultry",
  //   text: "Give the litter box a little love, with quick-clumping organic tofu pellets.",
  //   img: `${require("./images/products/mycoNewlogo2.png")}`,
  // },
  // {
  //   titile: "Vet Bind",
  //   id: "cattle",
  //   text: "Give the litter box a little love, with quick-clumping organic tofu pellets.",
  //   img: `${require("./images/products/mycoNewlogo2.png")}`,
  // },
  {
    titile: "Mycotoxease",
    id: "cattle",
    text: "Our Shield Against Harmful Mycotoxins",
    img: `${require("./images/products/myco.png")}`,
  },
  {
    titile: "Vet Zeo",
    id: "aqua",
    text: "For effective pond cleaning",
    img: `${require("./images/products/zeo.png")}`,
  },
  {
    titile: "Mycotoxease",
    id: "aqua",
    text: "Our Shield Against Harmful Mycotoxins",
    img: `${require("./images/products/myco.png")}`,
  },
  {
    titile: "Vet SDIC",
    id: "aqua",
    text: "Powerful water Disinfectant ",
    img: `${require("./images/products/vetsdic.png")}`,
  },
];

 

const AnimalNutrition = () => {
  const [nutrition, setNutrition] = useState("poultry");
  const [filter, setFilter] = useState(weCare);
  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);

  useEffect(() => {
    const product = weCare.filter((el) => el.id != nutrition);
    setFilter(product);
  }, [nutrition]);
  // console.log(filter);

  useEffect(() => {
    const horizontalScroll = () => {
      const sticky = document.querySelector(".sticky-soil");
      const stickyParent = document.querySelector(".sticky-parent-soil");

      const scrollWidth = sticky.scrollWidth;
      const verticalScrollHeight =
        stickyParent.getBoundingClientRect().height -
        sticky.getBoundingClientRect().height;

      const stickyPosition = sticky.getBoundingClientRect().top;
      if (stickyPosition > 1) {
        return;
      } else {
        const scrolled = stickyParent.getBoundingClientRect().top;
        sticky.scrollLeft =
          (scrollWidth / verticalScrollHeight) * -scrolled * 0.55;
      }
    };

    document.addEventListener("scroll", horizontalScroll);

    return () => {
      document.removeEventListener("scroll", horizontalScroll);
    };
  }, []);

  const navigate = useNavigate();
  return (
    <div>
      <div style={{ background: "#FBFFE8" }}>
        <div style={{ height: "100px" }}></div>
        <div data-aos="zoom-in">
          <p class="display-4 fw-bold"> Animal Nutrition </p>
          <p style={{ fontSize: "18px" }}>
            Elevating domestic animal nutrition to foster health, happiness,
            <br /> and vitality in our beloved companions
          </p>
          <div
            type="button"
            class="blueBtn mt-4 text-end m-auto"
            onClick={() => navigate("/contact")}
          >
            <div class="blueText">Contact us</div>
            <div class="blueBg">Contact us</div>
          </div>
          <br />
          <br />
        </div>

        <div class="col-11 m-auto" style={{ height: "500px" }}>
          <img
            src={require("./images/animalNutritionBg.jpg")}
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
            alt=""
          />
        </div>

        <div
          class="animalOption d-flex align-items-center justify-content-center my-5"
          data-aos="fade-up"
        >
          <Link
            activeClass="active"
            to="filterProduct"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            <div class="animal" onClick={() => setNutrition("poultry")}>
              <div
                class="p-3 px-4 animalIcon rounded-4"
                style={nutrition == "poultry" ? { background: "black" } : {}}
              >
                <img src={require("./images/poultry.png")} alt="" />
              </div>
              <div
                class="p-1 animalText"
                style={
                  nutrition == "poultry"
                    ? { color: "black", fontWeight: "600" }
                    : {}
                }
              >
                <p>Poultry</p>
              </div>
            </div>
          </Link>

          <Link
            activeClass="active"
            to="filterProduct"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            <div class="animal" onClick={() => setNutrition("cattle")}>
              <div
                class="p-3 px-4 animalIcon rounded-4"
                style={nutrition == "cattle" ? { background: "black" } : {}}
              >
                <img src={require("./images/cattle.png")} alt="" />
              </div>
              <div
                class="p-1 animalText"
                style={
                  nutrition == "cattle"
                    ? { color: "black", fontWeight: "600" }
                    : {}
                }
              >
                <p>Cattle</p>
              </div>
            </div>
          </Link>
          <Link
            activeClass="active"
            to="filterProduct"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            <div class="animal" onClick={() => setNutrition("aqua")}>
              <div
                class="p-3 px-4 animalIcon rounded-4"
                style={nutrition == "aqua" ? { background: "black" } : {}}
              >
                <img src={require("./images/aqua.png")} alt="" />
              </div>
              <div
                class="p-1 animalText"
                style={
                  nutrition == "aqua"
                    ? { color: "black", fontWeight: "600" }
                    : {}
                }
              >
                <p>Aqua</p>
              </div>
            </div>
          </Link>
        </div>

        <div
          class="col-11 rounded-5 text-white m-auto filterProduct "
          style={{ background: "#0081F3" }}
        >
          {nutrition == "poultry" ? (
            <div>
              <div class="">
                <br />
                <br />
                <p
                  class="fs-1 fw-semibold col-sm-12 col-10 m-auto "
                  data-aos="fade-up"
                >
                  Tailored Supplements <br />
                  for Maximum Efficiency
                </p>
                {/* <p class="col-sm-12 col-10 m-auto" data-aos="fade-up">
                  Tackling on-farm challenges with our exceptional specialty{" "}
                  <br /> ingredients for enhanced profitability.
                </p> */}
                <br />
                <br />
              </div>
              <div class="col-md-8 col-11 m-auto row">
                <div class="col-3 d-grid justify-content-center align-items-center">
                  <div
                    class="d-grid align-items-center justify-content-center "
                    style={{ marginLeft: "50px" }}
                    data-aos="zoom-in"
                    data-aos-delay="100"
                    data-aos-offset="0"
                  >
                    <div
                      class="vetminPro"
                      onClick={() => navigate("/all-products")}
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        src={require("./images/products/vetotm.png")}
                        style={{ width: "80%" }}
                        alt=""
                      />{" "}
                    </div>
                    <p class="fw-bold vetminName">Vet OTM</p>
                  </div>
                  <div
                    class="d-grid align-items-center justify-content-center my-5"
                    style={{ marginRight: "50px" }}
                    data-aos="zoom-in"
                    data-aos-delay="600"
                    data-aos-offset="0"
                  >
                    <div
                      class="vetminPro"
                      onClick={() => navigate("/all-products")}
                      style={{ cursor: "pointer" }}
                    >
                      {" "}
                      <img
                        src={require("./images/products/VETCHOL.png")}
                        style={{ width: "80%" }}
                        alt=""
                      />
                    </div>
                    <p class="fw-bold vetminName">Vet Chol </p>
                  </div>
                  <div
                    class="d-grid align-items-center justify-content-center"
                    style={{ marginLeft: "50px" }}
                    data-aos="zoom-in"
                    data-aos-delay="1100"
                    data-aos-offset="0"
                  >
                    <div
                      class="vetminPro"
                      onClick={() => navigate("/all-products")}
                      style={{ cursor: "pointer" }}
                    >
                      {" "}
                      <img
                        src={require("./images/products/mos.png")}
                        style={{ width: "80%" }}
                        alt=""
                      />
                    </div>
                    <p class="fw-bold vetminName">Vet Mos</p>
                  </div>
                </div>
                <div class="col-6 d-grid justify-content-center align-items-center">
                  <img
                    src={require("./images/poultryHan.png")}
                    style={{ width: "100%" }}
                    alt=""
                  />
                </div>
                <div class="col-3 d-grid justify-content-center align-items-center">
                  <div
                    class="d-grid align-items-center justify-content-center"
                    style={{ marginRight: "50px" }}
                    data-aos="zoom-in"
                    data-aos-delay="1600"
                    data-aos-offset="0"
                  >
                    <div
                      class="vetminPro"
                      onClick={() => navigate("/all-products")}
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        src={require("./images/products/vetsdic.png")}
                        style={{ width: "80%" }}
                        alt=""
                      />
                    </div>
                    <p class="fw-bold vetminName">Vet SDIC</p>
                  </div>
                  <div
                    class="d-grid align-items-center justify-content-center my-5"
                    style={{ marginLeft: "50px" }}
                    data-aos="zoom-in"
                    data-aos-delay="2100"
                    data-aos-offset="0"
                  >
                    <div
                      class="vetminPro"
                      onClick={() => navigate("/all-products")}
                      style={{ cursor: "pointer" }}
                    ></div>
                    <p class="fw-bold vetminName">Vet Bind</p>
                  </div>
                  <div
                    class="d-grid align-items-center justify-content-center"
                    style={{ marginRight: "50px" }}
                    data-aos="zoom-in"
                    data-aos-delay="2600"
                    data-aos-offset="0"
                  >
                    <div
                      class="vetminPro"
                      onClick={() => navigate("/all-products")}
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        src={require("./images/products/vetflora.png")}
                        style={{ width: "80%" }}
                        alt=""
                      />
                    </div>
                    <p class="fw-bold vetminName">Vet Flora</p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {nutrition == "cattle" ? (
            <div>
              <div class="">
                <br />
                <br />
                <p
                  class="fs-1 fw-semibold col-sm-12 col-10 m-auto "
                  data-aos="fade-up"
                >
                  Tailored Supplements <br />
                  for Maximum Efficiency
                </p>
                {/* <p class="col-sm-12 col-10 m-auto" data-aos="fade-up">
                  Tackling on-farm challenges with our exceptional specialty{" "}
                  <br /> ingredients for enhanced profitability.
                </p> */}
                <br />
                <br />
              </div>
              <div class=" col-md-8 col-11 m-auto row">
                <div class="col-3 d-grid justify-content-center align-items-center">
                  <div
                    class="d-grid align-items-center justify-content-center "
                    data-aos="zoom-in"
                    data-aos-delay="100"
                    data-aos-offset="0"
                  >
                    <div
                      class="vetminPro"
                      onClick={() => navigate("/all-products")}
                      style={{ cursor: "pointer" }}
                    ></div>
                    <p class="fw-bold vetminName">Vet Bind</p>
                  </div>
                </div>
                <div class="col-6 d-grid justify-content-center align-items-center">
                  <img
                    src={require("./images/cattleCow.png")}
                    style={{ width: "100%" }}
                    alt=""
                  />
                </div>
                <div class="col-3 d-grid justify-content-center align-items-center">
                  <div
                    class="d-grid align-items-center justify-content-center my-2"
                    data-aos="zoom-in"
                    data-aos-delay="600"
                    data-aos-offset="0"
                  >
                    <div
                      class="vetminPro"
                      onClick={() => navigate("/all-products")}
                      style={{ cursor: "pointer" }}
                    ></div>
                    <p class="fw-bold vetminName">Toxin Binder</p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {nutrition == "aqua" ? (
            <div>
              <div class="">
                <br />
                <br />
                <p
                  class="fs-1 fw-semibold col-sm-12 col-10 m-auto "
                  data-aos="fade-up"
                >
                  Tailored Supplements <br />
                  for Maximum Efficiency
                </p>
                {/* <p class="col-sm-12 col-10 m-auto" data-aos="fade-up">
                  Tackling on-farm challenges with our exceptional specialty{" "}
                  <br /> ingredients for enhanced profitability.
                </p> */}
                <br />
                <br />
              </div>
              <div class=" col-md-8 col-11 m-auto row">
                <div class="col-3 d-grid justify-content-center align-items-center">
                  <div
                    class="d-grid align-items-center justify-content-center"
                    style={{ marginLeft: "50px" }}
                    data-aos="zoom-in"
                    data-aos-delay="100"
                    data-aos-offset="0"
                  >
                    <div
                      class="vetminPro"
                      onClick={() => navigate("/all-products")}
                      style={{ cursor: "pointer" }}
                    ></div>
                    <p class="fw-bold vetminName">Toxin Binder </p>
                  </div>
                  <div
                    class="d-grid align-items-center justify-content-center my-5"
                    style={{ marginRight: "50px" }}
                    data-aos="zoom-in"
                    data-aos-delay="600"
                    data-aos-offset="0"
                  >
                    <div
                      class="vetminPro"
                      onClick={() => navigate("/all-products")}
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        src={require("./images/products/vetsdic.png")}
                        style={{ width: "80%" }}
                        alt=""
                      />
                    </div>
                    <p class="fw-bold vetminName">Vet SDIC </p>
                  </div>
                </div>
                <div class="col-6 d-grid justify-content-center align-items-center">
                  <img
                    src={require("./images/aquaFish.png")}
                    style={{ width: "100%" }}
                    alt=""
                  />
                </div>
                <div class="col-3 d-grid justify-content-center align-items-center">
                  <div
                    class="d-grid align-items-center justify-content-center"
                    data-aos="zoom-in"
                    data-aos-delay="1100"
                    data-aos-offset="0"
                  >
                    <div
                      class="vetminPro"
                      onClick={() => navigate("/all-products")}
                      style={{ cursor: "pointer" }}
                    >
                       <img
                        src={require("./images/products/zeo.png")}
                        style={{ width: "80%" }}
                        alt=""
                      />
                    </div>
                    <p class="fw-bold vetminName">Vet Zeo</p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <br />
          <br />
        </div>

        {nutrition == "poultry" ? (
          <div class="row col-11 m-auto justify-content-between mt-5 gap-3">
            <div class="col-md-5 col-11 p-0" data-aos="fade-up">
              <p class="fs-3 fw-semibold tJustify">
                Fuelling Feathers for Profitable Prosperity: Elevate Poultry
                Health and Harvests with Our Tailored Nutrition Solutions.
              </p>
            </div>
            <div class="col-md-5 col-11 p-0" data-aos="fade-up">
              <p class="fs-4 tJustify">
                Nutrition plays a crucial role in well-being of animals and
                indeed can have a significant impact on their health and
                productivity. Our comprehensive nutritional solutions, tailored
                for poultry birds deliver proven results and profitability to
                the farmers by improving FCR, weight gain and egg production.
              </p>
            </div>
          </div>
        ) : (
          ""
        )}

        {nutrition == "cattle" ? (
          <div class="row col-11 m-auto justify-content-between mt-5 gap-3">
            <div class="col-md-5 col-11 p-0" data-aos="fade-up">
              <p class="fs-3 fw-semibold tJustify">
                Elevating Farming Success: Nourishing Health and Prosperity for
                Your Livestock
              </p>
            </div>
            <div class="col-md-5 col-11 p-0" data-aos="fade-up">
              <p class="fs-4 tJustify">
                Nutrition plays a crucial role in well-being of animals and
                indeed can have a significant impact on their health and
                productivity. Our comprehensive nutritional solutions, tailored
                for every stage of dairy cow, from vitamin and mineral intake to
                increased milk production, deliver proven results, improved
                health and profitability to the farmers.
              </p>
            </div>
          </div>
        ) : (
          ""
        )}

        {nutrition == "aqua" ? (
          <div class="row col-11 m-auto justify-content-between mt-5 gap-3">
            <div class="col-md-5 col-11 p-0" data-aos="fade-up">
              <p class="fs-3 fw-semibold tJustify">
                Dive into Success: Enhancing Aquatic Life, Health, and Pond
                Prosperity with Our Comprehensive Solutions.
              </p>
            </div>
            <div class="col-md-5 col-11 p-0" data-aos="fade-up">
              <p class="fs-4 tJustify">
                Our comprehensive solutions, play a crucial role in well-being
                of aquatic life and indeed can have a significant impact on
                their health, productivity and pond life.
              </p>
            </div>
          </div>
        ) : (
          ""
        )}

        {/* <div
          class="col-11 m-auto d-flex  flex-wrap  gap-lg-0 gap-4 mt-5   "
          style={{ justifyContent: "space-evenly" }}
        >
          {OurCapab()}
        </div> */}
      </div>

      <div style={{ background: "linear-gradient(#FBFFE8 20% ,black 50%)" }}>
        <br />
        <br />
        <div
          class=" "
          style={{
            background: "#B0CB1F",
            borderRadius: " 50px 50px 50px 50px",
            position: "relative",
          }}
        >
          <br />
          <br />
          <p class="display-5 fw-semibold   " data-aos="fade-up">
            Other Products
          </p>

          <div class="productMobile">
            <br />
            <WeCare images={filter} />
            <br />
          </div>

          <div class="productPc">
            <div className="sticky-parent-soil">
              <div className="sticky-soil">
                <div className="horizontal-soil">
                  {filter.map((el) => {
                    return (
                      <div className="product">
                        <div
                          class=" col-md-10 col-11 m-auto productCartMainSoil"
                          style={{ height: "80vh" }}
                        >
                            <div
                              class='bg-light' style={{borderRadius:'25px 25px 0 0 '}}
                          >
                            <div class='col-8 m-auto'>

                            <img
                              src={el.img}
                              style={{ height:'35vh' }}
                              alt=""
                            />
                            </div>
                          </div>
                          <div
                            class="bg-light col-12 m-auto p-4 productCartSoil" style={{borderRadius:'0 0 25px 25px  '}}
                          >
                            
                            {/* <p class="fw-bold fs-2 ">{el.titile}</p> */}
                            <p
                              class="my-2 fs-5"
                              style={{ fontSize: "18px", fontWeight: "400" }}
                            >
                              {el.text}
                            </p>
                            <div
                              type="button"
                              class="blueBtn mt-4 text-end m-auto productCartSoilButton"
                              onClick={() => navigate("/all-products")}
                            >
                              <div class="blueText">Know More</div>
                              <div class="blueBg">Know More</div>
                            </div>
                          </div> 
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div style={{ height: "150px" }}></div>
        </div>
        <div style={{ marginTop: "-150px" }}>
          <UpFooter />
        </div>
      </div>
    </div>
  );
};

export default AnimalNutrition;
