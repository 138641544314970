import React, { useEffect, useState } from "react";
import "./soilCirclemain.css";
import { useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-scroll";

const Vetotm = () => {
  const navigate = useNavigate();
  return (
    <div class="col-12 row mt-3  m-0">
      <div class="col-md-7 col-12 text-start ">
        <div class="col-sm-10 m-auto " data-aos="fade-up">
          <p class="display-3 text-primary fw-bold">Vet OTM</p>
          <br />
          <p class="fs-3 fw-bold ">Organic trace mineral</p>
          <br />
          <p class="tJustify fw-semibold">
            Vet OTM is a unique blend of organic trace mineral formulated to
            suit modern day poultry farming.
          </p>
          <br />
          <p class="fs-3 fw-bold m-0 p-0 ">Features :</p>
          <ul class="m-0 tJustify ">
            <li>
              Lowest molecular weight of glycinate helps in better absorption.
            </li>
            <li>High bioavailability</li>
          </ul>
          <br />

          <p class="fs-3 fw-bold m-0 p-0 ">Broilers :</p>
          <ul class="m-0 tJustify ">
            <li>
              Improves livability, growth, FCR and carcass quality in broilers.
            </li>
            <li>Enhances immunity</li>
          </ul>
          <br />
          <p class="fs-3 fw-bold ">Layers and breeders : </p>
          <ul class="m-0 tJustify">
            <li>
              {" "}
              Improves fertility, hatchability and chick quality in breeders
            </li>
            <li> Improves footpad scoring</li>
            <li> Improves egg shell thickness and reduces breakage</li>
            <li> Reduces stress</li>
          </ul>
          <br />
          <p class="fs-3 fw-bold ">Mixing ratio (per metric ton of feed) :</p>
          <p class="fw-semibold">Broilers/ Layers: 500g</p>
          <p class="fw-semibold">Breeders: 750g – 1kg</p>
          <br />

          <div style={{ overflowX: "auto" }}>
            <table class="table table-bordered mt-3 ">
              <thead>
                <tr>
                  <th scope="col">Element</th>
                  <th scope="col">Value(g/kg)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Copper</td>
                  <td>10</td>
                </tr>
                <tr>
                  <td>Manganese</td>
                  <td>60</td>
                </tr>
                <tr>
                  <td>Zinc</td>
                  <td>60</td>
                </tr>
                <tr>
                  <td>Chromium</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Selenium</td>
                  <td>0.5</td>
                </tr>
                <tr>
                  <td>Iodine</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>Iron</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>Ligand</td>
                  <td>Glycinate</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-md-5 col-12 row justify-content-between m-0 p-0 gap-sm-0 gap-5 ">
        <div class="col-md-12 col-sm-8 col-10 m-auto " data-aos="zoom-in">
          <img
            src={require("./images/products/vetotm.png")}
            style={{
              width: "100%",
              height: "300px",
              objectFit: "contain",
              borderRadius: "50px",
            }}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

const Vetbind = () => {
  const navigate = useNavigate();
  return (
    <div class="col-12 row  mt-3  m-0">
      <div class="col-md-7 col-12 text-start ">
        <div class="col-sm-10 m-auto " data-aos="fade-up">
          <p class="display-3 text-primary fw-bold">Vet BIND</p>
          <br />
          <p class="tJustify fw-semibold">
            “Naturally occurring aluminosilicate MINED and PROCESSED according
            to tightly controlled specifications”
          </p>
          <br />
          <p class="fs-3 fw-bold ">Composition :</p>
          <p class="tJustify fw-semibold">
            Sodium Magnesium Aluminosilicate 100% W/W
          </p>
          <p class="tJustify fw-semibold">
            (Comprising of sodium oxide, Magnesium oxide, Aluminium oxide and
            Silicon dioxide)
          </p>
          <br />
          <p class="fs-3 fw-bold m-0 p-0 ">Features :</p>
          <ul class="m-0 tJustify  ">
            <li>High PDI (Pellet Durability Index)</li>
            <li>
              Highly absorptive material that holds 1.3 times its weight in
              water.
            </li>
            <li>A microgranule with more than 300 million particles/kg</li>
            <li>
              A feed additive with GRAS status (Generally Recognised As Safe)
            </li>
            <li>Distributes heat and steam evenly throughout the mash.</li>
            <li>No odour or palatability side effects</li>
          </ul>
          <br />

          <p class="fs-3 fw-bold ">Inclusion Rate:</p>
          <p class="fw-semibold tJustify">
            2.5 kg. Per Ton of feed (Or) as per recommendation of Nutritionist.
          </p>
          <br />
          <div style={{ overflowX: "auto" }}>
            <table class="table table-bordered mt-3 ">
              <thead>
                <tr>
                  <th scope="col">Nutritionists</th>
                  <th scope="col">Millers</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>High LHC, to load more Fat energy in diet</td>
                  <td>Increased production</td>
                </tr>
                <tr>
                  <td>Inert (will not support Mould development)</td>
                  <td>Lower energy costs</td>
                </tr>
                <tr>
                  <td>No palatability Problems</td>
                  <td>Easier handling</td>
                </tr>
                <tr>
                  <td>No effect on nutrient availability</td>
                  <td>Increased pellet durability</td>
                </tr>
                <tr>
                  <td>Increased die life</td>
                  <td>Feed Bridging</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-md-5 col-12 row justify-content-between m-0 p-0 gap-sm-0 gap-5 ">
        <div class="col-md-12 col-sm-8 col-10 m-auto " data-aos="zoom-in">
          {/* <img
            src={require("./images/soil.png")}
            style={{
              width: "100%",
              height: "300px",
              objectFit: "contain",
              borderRadius: "50px",
            }}
            alt=""
          /> */}
        </div>
      </div>
    </div>
  );
};

const Vetchol = () => {
  const navigate = useNavigate();
  return (
    <div class="col-12 row  m-0">
      <div class="col-md-7 col-12 text-start ">
        <div class="col-sm-10 m-auto " data-aos="fade-up">
          <p class="display-3 text-primary fw-bold">Vet CHOL</p>
          <br />
          <p class="fs-3 fw-bold tJustify ">Unlocking Optimal Health and Nervous System Vitality!</p>
            <br />
          <p class="tJustify fw-semibold">
            Choline Chloride is a B-Complex vitamin, a critical building block
            of nutrition and essential nutrient for optimal health. The main
            function of choline Chloride is to act as a lipotropic agent and
            prevents the abnormal fatty infiltration in the liver thus ensures
            proper metabolism of the body & effective utilization of the
            nutrients. Moreover, it helps in the formation of an excitatory
            neurotransmitter-acetylcholine, which is responsible for the proper
            functioning of the nervous system.
          </p>
          <br />

          <p class="fs-3 fw-bold m-0 p-0 ">Functions of choline chloride :</p>
          <ul class="m-0 tJustify ">
            <li>Building and maintaining cell structures</li>
            <li>Fat metabolism of the liver</li>
            <li>Synthesis of Acetylcholine (essential)</li>
            <li>Methyl-group donor (non-essential) via betaine</li>
          </ul>
          <br />
          <p class="fs-3 fw-bold ">Composition :</p>
          <ul class="m-0 tJustify">
            <li> Choline chloride 60% (w/w)</li>
            <li> Carrier Corn cob</li>
          </ul>
          <br />

          <p class="fs-3 fw-bold ">Key features of VetChol :</p>
          <ul class="m-0 tJustify">
            <li>Low Trimethylamine (TMA) content less than 250ppm </li>
            <li>High Bioavailability </li>
            <li>
              Uniform particle size to ensure uniform distribution in feed{" "}
            </li>
            <li>Compatibility with all fat- and water-soluble vitamins </li>
            <li>Moisture content less than 5% </li>
            <li>Free from toxin/Bacterial contamination </li>
          </ul>
          <br />

          <p class="fs-3 fw-bold ">Benefits :</p>
          <ul class="m-0 tJustify">
            <li>Improves egg production </li>
            <li>Improves feed conversion efficiency</li>
            <li>Decrease serum cholesterol in the broiler </li>
            <li>Prevents perosis and liver enlargement </li>
          </ul>
          <br />
          <p class="fs-3 fw-bold ">Recommended Dosage (g/MT of feed) :</p>
          <ul class="m-0 tJustify">
            <li>Layer 1000 – 1500 </li>
            <li>Breeder 1900 – 2300</li>
            <li>Broiler 1900 - 2300 </li>
          </ul>
        </div>
      </div>
      <div class="col-md-5 col-12 row justify-content-between m-0 p-0 gap-sm-0 gap-5 ">
        <div class="col-md-12 col-sm-8 col-10 m-auto " data-aos="zoom-in">
          <img
            src={require("./images/products/VETCHOL.png")}
            style={{
              width: "100%",
              height: "300px",
              objectFit: "contain",
              borderRadius: "50px",
            }}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

const Vetflora = () => {
  const navigate = useNavigate();
  return (
    <div class="col-12 row  m-0">
      <div class="col-md-7 col-12 text-start ">
        <div class="col-sm-10 m-auto " data-aos="fade-up">
          <p class="display-3 text-primary fw-bold">Vet FLORA </p>
          <br />
          <p class="fs-3 fw-bold ">Elevate Gut Health, Empower Wellness!</p>

          <br />
          <p class="tJustify fw-semibold">
            {" "}
            VetFlora is a blend of multi- strain sporulating Aerobic,
            facultative anerobic and anaerobic Bacillus spp. for optimising Gut
            microbiota.{" "}
          </p>
          <br />

          <p class="fs-3 fw-bold ">VetFlora consists of :</p>
          <ul class="m-0 tJustify ">
            <li>
              <b>Bacillus subtilis</b> is a Gram-Positive Aerobic Probiotic.
            </li>
            <li>
              <b>Bacillus coagulans</b> is a Gram-Positive Facultative Anaerobic
              Probiotic.{" "}
            </li>
            <li>
              <b>Bacillus Licheniformis</b> is a Gram-Positive Facultative
              Anaerobic Probiotic,
            </li>
          </ul>
          <br />

          <p class="tJustify fw-bold mt-2">
            VetFlora provides complete protection from crop to cloaca.{" "}
          </p>
          <ul class="m-0 tJustify">
            <li>
              {" "}
              <b>Foregut</b> - Bacillus subtilis (Has impact on growth
              performance)
            </li>
            <li>
              <b>Mid gut</b> - Bacillus subtilis, Bacillus coagulans (Improves
              growth performance and increased feed digestibility in poultry)
            </li>
            <li>
              {" "}
              <b>Hindgut</b> - Bacillus licheniformis (Prevents Necrotic
              Enteritis)
            </li>
          </ul>
          <br />
          <p class="tJustify fw-bold mt-2">
            Our choice of species in VetFlora has complete protection from
            foregut to hindgut which helps for better digestion and absorption.
          </p>
          <br />

          <p class="fs-3 fw-bold ">Advantages of VetFlora:</p>
          <ul class="m-0 tJustify">
            <li>Provides complete protection from crop to cloaca. </li>
            <li>
              Has proven activity against Clostridium perfringens, Salmonella
              enteritis’s and E. coli{" "}
            </li>
            <li>Helps for better digestion and absorption. </li>
            <li>
              Maximises economic returns by supporting the liveability and
              productivity{" "}
            </li>
            <li>
              Good enzymatic activity, produces enzymes like amylase, protease,
              cellulase and Lipase which improves digestibility and energy
              conversion of the feed.{" "}
            </li>
          </ul>
          <br />

          <p class="tJustify fw-semibold">
            Our choice of species in VetFlora has complete protection from
            foregut to hindgut which helps for better digestion and absorption.
          </p>
          <br />

          <p class="fs-3 fw-bold ">Mixing ratio: </p>
          <ul class="m-0 tJustify">
            <li>Probiotic blend: (Total strength 2 billion cfu/g) </li>
            <li>Broilers/Layers: 100g/ton of feed </li>
            <li>
              Breeders: Double the dose of broilers and layers. (As directed by
              the nutritionist)
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-5 col-12 row justify-content-between m-0 p-0 gap-sm-0 gap-5 ">
        <div class="col-md-12 col-sm-8 col-10 m-auto " data-aos="zoom-in">
          <img
            src={require("./images/products/vetflora.png")}
            style={{
              width: "100%",
              height: "300px",
              objectFit: "contain",
              borderRadius: "50px",
            }}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

const Vetmos = () => {
  const navigate = useNavigate();
  return (
    <div class="col-12 row  m-0">
      <div class="col-md-7 col-12 text-start ">
        <div class="col-sm-10 m-auto " data-aos="fade-up">
          <p class="display-3 text-primary fw-bold">Vet MOS</p>
          <br />
          <p class="fs-3 fw-bold ">For Comprehensive toxin management</p>
          <br />
          <p class="tJustify fw-semibold">
            <b>VetMos</b> is a broad-spectrum mycotoxin binder containing
            activated dipolar clay i.e., Hydrated Sodium calcium
            Aluminiuminosilicate, Mannan oligosaccharide <b>(MOS)</b> derived
            from yeast cell wall, which effectively binds major mycotoxins
          </p>
          <br />
          <p class="fs-3 fw-bold m-0 p-0 ">Features :</p>
          <ul class="m-0  tJustify">
            <li>Dipolar silicate binds polar and non-polar toxins in feed </li>
            <li>Aids in detoxification of toxins from the liver and body </li>
            <li>β-glucan adsorbs polar and non – polar mycotoxins. </li>
            <li>MOS and β-glucan boost immunity </li>
            <li>
              MOS and β-glucan enhance intestinal health by preventing
              colonisation of pathogens in the intestinal tract.
            </li>
          </ul>
          <br />
          <p class="fs-3 fw-bold ">Layers and breeders</p>
          <ul class="m-0 tJustify">
            <li>
              Protects birds from mycotoxins, such as Aflatoxins, Ochratoxins,
              Trichothecenes (T-2), Zearalenone, Deoxynivalenol (DON),
              Fumonisins.
            </li>
            <li>
              MOS and β-glucan have their potential on improving growth
              performance, regulating intestinal microbiota, and stimulating
              immune response.
            </li>
            <li>Improves the FCR and egg production</li>
            <li>Improves liveability and uniformity of the flock</li>
          </ul>
          <br />
          <p class="fs-3 fw-bold ">Inclusion ratio:</p>
          <ul class="m-0 tJustify">
            <li>
              Broilers/Layers/Breeder: Add at 0.5 – 1kg per ton of feed (Based
              on the level of contamination and moisture, the mixing ratio can
              be varied in or as directed by poultry nutritionist)
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-5 col-12 row justify-content-between m-0 p-0 gap-sm-0 gap-5 ">
        <div class="col-md-12 col-sm-8 col-10 m-auto " data-aos="zoom-in">
          <img
            src={require("./images/products/vetmos.png")}
            style={{
              width: "100%",
              height: "300px",
              objectFit: "contain",
              borderRadius: "50px",
            }}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

const Vetsdic = () => {
  const navigate = useNavigate();
  return (
    <div class="col-12 row  m-0">
      <div class="col-md-7 col-12 text-start ">
        <div class="col-sm-10 m-auto " data-aos="fade-up">
          <p class="display-3 text-primary fw-bold">Vet SDIC</p>
          <br />
          <p class="fs-3 fw-bold ">Purify with Power: Ultimate Defense Against Waterborne Threats!</p>

          <br />
          <p class="tJustify fw-semibold">
            <b>SDIC tablets</b> are superior anti-microbial agent and a strong
            oxidant, very effectively kills harmful Pathogens like Bacteria,
            E-Coli, Salmonella, Virus, Parasites, etc., present in the water
            which are responsible for water borne diseases and spread disease
            outbreaks..
          </p>
          <br />
          <p class="fs-3 fw-bold ">Composition:</p>
          <p class="  fw-semibold">Each effervescent tablet contains</p>
          <p class="  fw-semibold">SDIC (Sodium Dichloroisocyanurate): 5gm</p>
          <p class="  fw-semibold">Available chlorine: 3gm</p>
          <p class="  fw-semibold">Excipients: Q.s</p>
          <br />
          <p class="fs-3 fw-bold m-0 p-0 ">Features :</p>
          <ul class="m-0 tJustify ">
            <li>Chlorine is Highly stable in water for 24 to 48 hours. </li>
            <li>
              Effectively kills E-Coli, Salmonella, Virus, Protozoa, Fungi,
              Spores, Parasites, etc.,
            </li>
            <li>100% Soluble without any sludge</li>
            <li>Eradicates biofilm and ensures clean pipe line and nipples.</li>
            <li>Low contact time and Stability</li>
          </ul>
          <br />
          <p class="fs-3 fw-bold ">Layers and breeders :</p>
          <ul class="m-0 tJustify">
            <li>Overall Hygiene and Biosecurity – Avoids disease outbreak</li>
            <li>
              {" "}
              Recontamination is controlled by its residual activity (minimum 48
              hrs. stable in water)
            </li>
            <li>
              {" "}
              Disinfected safe water delivered at nipple point for chicks.
            </li>
            <li>
              {" "}
              Economic benefit with reduction in mortality and improved FCR.
            </li>
          </ul>
          <br />

          <div style={{ overflowX: "auto" }}>
            <table class="table table-bordered mt-3 ">
              <thead>
                <tr>
                  <th scope="col">Application</th>
                  <th scope="col">Application Mixing Rate</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Drinking water sanitization (Normal), Poultry House
                    Misting/Fogging
                  </td>
                  <td>1 tab/600lit of water</td>
                </tr>
                <tr>
                  <td>Drinking water sanitization (Contaminated water) </td>
                  <td>1 tab/300lit of water</td>
                </tr>
                <tr>
                  <td>Egg mopping, Worker Hand sanitization </td>
                  <td> 1 tab/30lit of water </td>
                </tr>
                <tr>
                  <td>Foot bath</td>
                  <td>1 tab/3lit of water</td>
                </tr>
                <tr>
                  <td>Aqua Culture Pond sanitization</td>
                  <td>1 tab/60lit of water</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-md-5 col-12 row justify-content-between m-0 p-0 gap-sm-0 gap-5 ">
        <div class="col-md-12 col-sm-8 col-10 m-auto " data-aos="zoom-in">
          <img
            src={require("./images/products/vetsdic.png")}
            style={{
              width: "100%",
              height: "300px",
              objectFit: "contain",
              borderRadius: "50px",
            }}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

const Vetzeo = () => {
  const navigate = useNavigate();
  return (
    <div class="col-12 row  m-0">
      <div class="col-md-7 col-12 text-start ">
        <div class="col-sm-10 m-auto " data-aos="fade-up">
          <p class="display-3 text-primary fw-bold">Vet ZEO</p>
          <br />
          <p class="fs-3 fw-bold ">For effective pond cleaning</p>
          <br />
          <p class="fs-3 fw-bold m-0 p-0 ">Features :</p>
          <ul class="m-0 tJustify ">
            <li>
              The granular form reaches to the pond bottom and gives good
              adsorption of toxic gases and make the habitat clean and healthy
              for aquatic life.
            </li>
            <li>Purifies water and improves dissolved oxygen content.</li>
            <li>Works in fresh and salt water.</li>
            <li>No residues and is environmental friendly.</li>
          </ul>
          <br />
          <p class="fs-3 fw-bold ">Benefits:</p>
          <ul class="m-0 tJustify">
            <li>Maintains Clean Pond from bottom.</li>
            <li>
              Higher CEC (300) and controls obnoxious gases like NH4, H2S and
              other poisonous gases in water.
            </li>
            <li>Improves the soil and water quality.</li>
            <li>Reduces spread of diseases.</li>
          </ul>
          <br />

          <p class="fs-3 fw-bold m-0 p-0 ">Chemical composition:</p>
          <p class="  fw-semibold">SiO2 50 – 60 %c</p>
          <p class="  fw-semibold">Al2O2 10 – 15%c</p>
          <p class="  fw-semibold">Fe2O2 2 – 4 %</p>
          <p class="  fw-semibold">CaO 10 – 15%c</p>
        </div>
        <br />
      </div>
      <div class="col-md-5 col-12 row justify-content-between m-0 p-0 gap-sm-0 gap-5 ">
        <div class="col-md-12 col-sm-8 col-10 m-auto " data-aos="zoom-in">
          <img
            src={require("./images/products/zeo.png")}
            style={{
              width: "100%",
              height: "300px",
              objectFit: "contain",
              borderRadius: "50px",
            }}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

// const Mycotoxease = () => {
//   const navigate = useNavigate();
//   return (
//     <div class="col-12 row  m-0">
//       <div class="col-md-7 col-12 text-start ">
//         <div class="col-sm-10 m-auto " data-aos="fade-up">
//           <p class="display-3 text-primary fw-bold">MYCOTOXEASE</p>
//           <br />
//           <p class="fs-3 fw-bold ">Dipolar Toxin Binder</p>
//           <br />
//           <p class="tJustify fw-semibold">
//             <b>SDIC tablets</b> are superior anti-microbial agent and a strong
//             oxidant, very effectively kills harmful Pathogens like Bacteria,
//             E-Coli, Salmonella, Virus, Parasites, etc., present in the water
//             which are responsible for water borne diseases and spread disease
//             outbreaks..
//           </p>
//           <br />
//           <p class="fs-3 fw-bold ">Composition:</p>
//           <p class="  fw-semibold">Each effervescent tablet contains</p>
//           <p class="  fw-semibold">SDIC (Sodium Dichloroisocyanurate): 5gm</p>
//           <p class="  fw-semibold">Available chlorine: 3gm</p>
//           <p class="  fw-semibold">Excipients: Q.s</p>
//           <br />
//           <p class="fs-3 fw-bold m-0 p-0 ">Features :</p>
//           <ul class="m-0 ">
//             <li>Chlorine is Highly stable in water for 24 to 48 hours. </li>
//             <li>
//               Effectively kills E-Coli, Salmonella, Virus, Protozoa, Fungi,
//               Spores, Parasites, etc.,
//             </li>
//             <li>100% Soluble without any sludge</li>
//             <li>Eradicates biofilm and ensures clean pipe line and nipples.</li>
//             <li>Low contact time and Stability</li>
//           </ul>
//           <br />
//           <p class="fs-3 fw-bold ">Layers and breeders :</p>
//           <ul class="m-0">
//             <li>Overall Hygiene and Biosecurity – Avoids disease outbreak</li>
//             <li>
//               {" "}
//               Recontamination is controlled by its residual activity (minimum 48
//               hrs. stable in water)
//             </li>
//             <li>
//               {" "}
//               Disinfected safe water delivered at nipple point for chicks.
//             </li>
//             <li>
//               {" "}
//               Economic benefit with reduction in mortality and improved FCR.
//             </li>
//           </ul>
//           <br />
//           <div style={{ overflowX: "auto" }}>
//             <table class="table table-bordered mt-3 ">
//               <thead>
//                 <tr>
//                   <th scope="col">Application</th>
//                   <th scope="col">Application Mixing Rate</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 <tr>
//                   <td>
//                     Drinking water sanitization (Normal), Poultry House
//                     Misting/Fogging
//                   </td>
//                   <td>1 tab/600lit of water</td>
//                 </tr>
//                 <tr>
//                   <td>Drinking water sanitization (Contaminated water) </td>
//                   <td>1 tab/300lit of water</td>
//                 </tr>
//                 <tr>
//                   <td>Egg mopping, Worker Hand sanitization </td>
//                   <td> 1 tab/30lit of water </td>
//                 </tr>
//                 <tr>
//                   <td>Foot bath</td>
//                   <td>1 tab/3lit of water</td>
//                 </tr>
//                 <tr>
//                   <td>Aqua Culture Pond sanitization</td>
//                   <td>1 tab/60lit of water</td>
//                 </tr>
//               </tbody>
//             </table>
//           </div>
//         </div>
//       </div>
//       <div class="col-md-5 col-12 row justify-content-between m-0 p-0 gap-sm-0 gap-5 ">
//         <div class="col-md-12 col-sm-8 col-10 m-auto " data-aos="zoom-in">
//           <img
//             src={require("./images/products/myco.png")}
//             style={{
//               width: "100%",
//               height: "300px",
//               objectFit: "contain",
//               borderRadius: "50px",
//             }}
//             alt=""
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

const Mycotoxease = () => {
  const navigate = useNavigate();
  return (
    <div class="col-12 row  m-0">
      <div class="col-md-7 col-12 text-start ">
        <div class="col-sm-10 m-auto " data-aos="fade-up">
          <p class="display-3 text-primary fw-bold">MYCOTOXEASE</p>
          {/* <br /> */}
          <p class="fs-3 fw-bold ">Our Shield Against Harmful Mycotoxins!</p>
          <br />
          <p class='tJustify fw-semibold'>In the realm of animal husbandry and aquaculture, mycotoxins pose an ongoing threat to the health and performance of cattle, poultry, and aquatic species. Fortunately, we offer a solution in three specialized forms: MYCOTOXEASE for Cattle, Poultry, and Aqua Life.</p>
          <div>

            <p class="fs-1 fw-bold" style={{color:'#B0CB1F'}}>Mycotoxease - Poultry :</p>
            <ul class="m-0 tJustify ">
              <li><b>Mycotoxin Defense:</b> Protects poultry, enhancing feed conversion and egg production.</li>
              <li><b>Mortality Reduction:</b> Controls mycotoxin-related mortality.</li>
              <li><b>Immune Support:</b> Reduces immunosuppression caused by mycotoxins.</li>
              <li><b>Toxin Binding: </b>Binds both polar and non-polar toxins in feed.</li>
              <li><b>Gut Health Maintenance: </b>Prevents pathogenic bacterial attachment.</li>
            </ul>
            <p class='tJustify'><b>Mixing Ratio:</b> For routine usage, add 500 gm – 1 kg per ton of feed. Consult a nutritionist to adjust the ratio based on contamination levels and moisture content, which can go up to 2 kg per ton.</p>
          </div>
    <br />
    <div>

<p class="fs-1 fw-bold" style={{color:'#B0CB1F'}}>Mycotoxease - Cattle :</p>
<ul class="m-0  tJustify ">
  <li><b>Mycotoxin Protection:</b> Safeguards dairy animals from mycotoxin contamination.</li>
  <li><b>Mould Inhibition:</b> Prevents mold growth in feed.</li>
  <li><b>Aflatoxin Control:</b> Manages Aflatoxin M1 levels in milk.</li>
  <li><b>Gut Health Enhancement:  </b> Promotes beneficial intestinal microflora.</li>
  <li><b>Feed Palatability:</b>Prevents feed caking, improving palatability.</li>
</ul>
<p class='tJustify'><b>Inclusion Ratio:</b>  Add 1.2 – 2 kg of Mycotoxease per ton of feed for optimal results.</p>
</div>
<br />
<div>

<p class="fs-1 fw-bold" style={{color:'#B0CB1F'}}>Mycotoxease - Aqua :</p>
<ul class="m-0 tJustify ">
  <li><b>Toxic Gas Absorption:</b>Helps absorb ammonia, sulfur dioxide, and hydrogen sulfide.</li>
  <li><b>Toxin Removal:</b> Binds organophosphates and pesticide toxins.</li>
  <li><b>Ecosystem Promotion:</b> Supports plankton growth and water clarity.</li>
  <li><b>Oxygen Enhancement: </b>Improves dissolved oxygen levels.</li>
  <li><b>Healthy Pond Environment: </b>Creates a favorable environment for aquatic life.</li>
</ul>
<p class='tJustify'><b>Usage : </b> Apply 1-2 kg per ton of feed or 5 kg per hectare in water,</p>
</div>
         
        </div>
      </div>
      <div class="col-md-5 col-12 row justify-content-between m-0 p-0 gap-sm-0 gap-5 ">
        <div class="col-md-12 col-sm-8 col-10 m-auto " data-aos="zoom-in">
          <img
            src={require("./images/products/myco.png")}
            style={{
              width: "100%",
              height: "300px",
              objectFit: "contain",
              borderRadius: "50px",
            }}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

const SoilCirclemain = () => {
  const [rotationAngle, setRotationAngle] = useState(0);
  const [filterProduct, setFilterProduct] = useState("vetotm");

  useEffect(() => {
    const texts = document.querySelectorAll(".text p span");

    texts.forEach((span, i) => {
      span.style.transform = `rotate(${rotationAngle + i * 45}deg)`;
    });
  }, [rotationAngle]);

  const handleTextClick = (e) => {
    // let element = document.getElementsByClassName("allProduct");
    // element.scrollIntoView({behavior:"smooth", block: "end", inline:"nearest"});
    // <Link  activeClass="active" to="allProduct" spy={true} smooth={true} offset={-70} duration={500}></Link>
    setRotationAngle((prevAngle) => prevAngle + 50); // Change the angle as per your requirement
    setFilterProduct(e);
  };

  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);

  return (
    <div class="col-12 row align-items-center m-0 flex-column-reverse flex-md-row">
      <div class="col-lg-9 col-md-8 col-11 m-auto p-0 allProduct mt-5">
        {filterProduct == "vetotm" ? <Vetotm /> : ""}
        {filterProduct == "vetbind" ? <Vetbind /> : ""}
        {filterProduct == "vetchol" ? <Vetchol /> : ""}
        {filterProduct == "vetflora" ? <Vetflora /> : ""}
        {filterProduct == "vetmos" ? <Vetmos /> : ""}
        {filterProduct == "vetsdic" ? <Vetsdic /> : ""}
        {filterProduct == "vetzeo" ? <Vetzeo /> : ""}
        {filterProduct == "mycotoxease" ? <Mycotoxease /> : ""}
      </div>

      <div class="col-lg-3 col-md-4 col-11 m-auto py-5">
        <div className="circle" data-aos="zoom-in">
          <div className="logo">
            <p class="fs-1 fw-bold" >
              Choose <br /> Products
            </p>
          </div>
          <div className="text">
            <p className="text-white  ">
              <span
                onClick={() => handleTextClick("vetotm")}
                style={
                  filterProduct == "vetotm"
                    ? { color: "black", fontWeight: "bold" }
                    : {}
                }
              >
                {" "}
                Vet OTM
              </span>
              <span
                onClick={() => handleTextClick("vetbind")}
                style={
                  filterProduct == "vetbind"
                    ? { color: "black", fontWeight: "bold" }
                    : {}
                }
              >
                {" "}
                Vet Bind{" "}
              </span>
              <span
                onClick={() => handleTextClick("vetchol")}
                style={
                  filterProduct == "vetchol"
                    ? { color: "black", fontWeight: "bold" }
                    : {}
                }
              >
                {" "}
                Vet Chol{" "}
              </span>
              <span
                onClick={() => handleTextClick("vetflora")}
                style={
                  filterProduct == "vetflora"
                    ? { color: "black", fontWeight: "bold" }
                    : {}
                }
              >
                {" "}
                Vet Flora{" "}
              </span>
              <span
                onClick={() => handleTextClick("vetmos")}
                style={
                  filterProduct == "vetmos"
                    ? { color: "black", fontWeight: "bold" }
                    : {}
                }
              >
                {" "}
                Vet MOS{" "}
              </span>
              <span
                onClick={() => handleTextClick("vetsdic")}
                style={
                  filterProduct == "vetsdic"
                    ? { color: "black", fontWeight: "bold" }
                    : {}
                }
              >
                {" "}
                Vet SDIC{" "}
              </span>
              <span
                onClick={() => handleTextClick("vetzeo")}
                style={
                  filterProduct == "vetzeo"
                    ? { color: "black", fontWeight: "bold" }
                    : {}
                }
              >
                {" "}
                Vet ZEO{" "}
              </span>
              <span
                onClick={() => handleTextClick("mycotoxease")}
                style={
                  filterProduct == "mycotoxease"
                    ? { color: "black", fontWeight: "bold" }
                    : {}
                }
              >
                {" "}
                Mycotoxease{" "}
              </span>
            </p>
          </div>
        </div>
      </div>


    </div>
  );
};

export default SoilCirclemain;
