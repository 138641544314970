import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";

const EnquiryForm = ({ data }) => {
  
  const [contact,setContact]= useState({})

 

  const contactInfo = (e)=>{
    const {name,value}= e.target;
    setContact({...contact,[name]:value})
}


const sendInfo =()=>{
  if(contact?.name && contact?.name != "" &&
  contact?.phone && contact?.phone != "" &&
  contact?.email && contact?.email != "" &&
  contact?.message && contact?.message != ""){
  
          axios.post(`https://hi-labsolution.net/api/vetmin-contact.php`,contact)
          .then((res)=>{
              // console.log(res);
              if(res.data.responseStatus == true){
                  Swal.fire({
                    position: "center",
                    icon: "success",
                    title: `${res.data.responseMessage}`,
                    showConfirmButton: false,
                    timer: 2000,
                  })
                  setContact({
                      "name" : '',
                      'phone':'',
                      'email': '',
                      'message':''
                  })
                  data(false)
              }else{
                  Swal.fire({
                      position: "center",
                      icon: "error",
                      title: `${res.data.responseMessage}`,
                      showConfirmButton: false,
                      timer: 2000,
                    })
              }
          }).catch((err)=>{
              console.log(err);
          })
      
  }else{
      Swal.fire({
          position: "center",
          icon: "error",
          title: `Fill all filds`,
          showConfirmButton: false,
          timer: 2000,
        })
  }
}
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        position: "fixed",
        top: "0",
        background: "rgba(0,0,0,.8)",
        zIndex: "2",
      }}
    >
      <br />
      <br />
      <div
        class="col-lg-6 col-11 m-auto"
        style={{
          height: "85vh",
          // overflowY: "auto",
        }}
      >
        <div class="text-end">
          <p style={{ cursor: "pointer" }}>
            <i class="bi bi-x-lg text-white" onClick={() => data(false)}></i>
          </p>
          <br />
        </div>
        <div class="" style={{ background: "#0081F3" }}>
          <div class="col-11 m-auto ">
            <br />
            <p class="fw-bold fs-3 text-start text-white" data-aos="fade-up">
              Fill the below <br /> following details
            </p>
            <div class="col-md-10 col-12  m-auto mt-4">
              <input
                type="text"
                class="form-control rounded-5 mt-3 text-white"
                style={{ background: "transparent" }}
                placeholder="Full Name"
                data-aos="fade-up"
                name='name'
                value={contact?.name}
                onChange={contactInfo}
              />
              
              <input
                type="text"
                class="form-control rounded-5 mt-3 text-white"
                style={{ background: "transparent" }}
                placeholder="Phone Number"
                data-aos="fade-up"
                name='phone'
                value={contact?.phone}
                onChange={contactInfo}
              />
              <input
                type="text"
                class="form-control rounded-5 mt-3 text-white"
                style={{ background: "transparent" }}
                placeholder="Email"
                data-aos="fade-up"
                name='email'
                value={contact?.email}
                onChange={contactInfo}
              />
              <textarea
                type="text"
                class="form-control rounded-5 mt-3 text-white"
                style={{ background: "transparent" }}
                placeholder="Message"
                data-aos="fade-up"
                name='message'
                value={contact?.message}
                onChange={contactInfo}
              />

              <div class="d-flex justify-content-end " data-aos="fade-up">
                <div type="button" class="whiteBtn my-4 text-end" onClick={sendInfo}>
                  <div class="whiteText">Submit</div>
                  <div class="whiteBg">Submit</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnquiryForm;
